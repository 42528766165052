import DataTable from 'react-data-table-component'

interface ITable {
  data: any[]
  columns: any[]
}

const customStyles = {
  headCells: {
    style: {
      fontSize: 14,
      color: '#666666',
    },
  },
  rows: {
    style: {
      fontSize: 14,
      minHeight: '60px',
      borderBottomWidth: 0,

      '&:not(:last-of-type)': {
        borderBottomWidth: '0',
      },
      '&:nth-child(odd)': {
        backgroundColor: '#F8F8FD',
      },
      '&:last-child': {
        marginBottom: 20,
      },
    },
  },
}

const Table = ({ data, columns }: ITable) => {
  return (
    <div>
      <DataTable
        columns={columns}
        data={data || []}
        pagination
        noHeader
        customStyles={customStyles}
      />
    </div>
  )
}

export default Table
