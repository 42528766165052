import { axiosClient } from '../utils/axiosClient'


export const createNavigationButton = async ({ data, token }: any) => {
  try {
    // console.log('Request Data:', data); 
    const response = await axiosClient.post(`/admin/navigations`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, 
      },
    });
    // console.log('Response:', response.data); 
    return response;
  } catch (error: any) {
    console.error('Request Error:', error); 
    throw new Error('An error occurred. Please try again.');
  }
};

export const getNavigationButton = async (token: string) => {
  try {
    const response = await axiosClient.get(`/admin/navigations`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data; 
  } catch (error: any) {
    console.error({ error });
    throw new Error('An error occurred. Please try again.');
  }
};

export const getSingleNavigationButton = async ({ token, id }: any) => {
  try {
    const response = await axiosClient.get(`/admin/navigations/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    return response.data; 
  } catch (error: any) {
    console.error({ error });
    throw new Error('An error occurred. Please try again.');
  }
}

export const updateNavigationButton = async ({ data, token, id }: any) => {
  try {
    const response = await axiosClient.put(`/admin/navigations/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data; 
  } catch (error: any) {
    console.error({ error });
    throw new Error('An error occurred. Please try again.');
  }
};

