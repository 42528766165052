// REACT DEFAULTS
import { ChangeEvent, FC, FormEvent, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCookies } from "react-cookie";
import ReactS3Client from "react-aws-s3-typescript";

// COMPONENTS & UTILS
import Modal from "../../../../components/Modal";
import { s3Config } from "../../../../utils/s3Config";
// import { getExtras } from "../../../../handlers/extraHandler";
import { Oval } from "react-loader-spinner";
import { toast } from "../../../../components/toast/toast";
import {
  getProductCategories,
  getSingleProduct,
  updateProductHandler,
} from "../../../../handlers/productHandler";

// IMAGES & ICONS
import upload from "../../../../assets/uploadPNG.png";

// STYLES
import styles from "./UpdateProductModal.module.css";

const UpdateProductModal: FC<UpdateProductModalProps> = ({
  isOpen,
  onClose,
  slug,
}) => {
  const queryClient = useQueryClient();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const navigate = useNavigate();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [imageURL, setImageURL] = useState<(string | undefined)[]>([]);
  // const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  const selectedImgRef = useRef<HTMLImageElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // GET PRODUCT CATEGORY SELECT DATA
  const { data: product_category } = useQuery<ICategories[]>("categories", () =>
    getProductCategories(cookies["sofresh-admin-token"])
  );

  // GET EXTRAS CHECKBOX DATA
  // const { data: extrasOptions } = useQuery<IExtras[]>("extras", () =>
  //   getExtras(cookies["sofresh-admin-token"])
  // );

  // GET STORE DATA TO PRE-POPULATE THE FORM
  const { data: productData, isLoading: isProductDataLoading } = useQuery(
    [`${slug}-product`],
    () => getSingleProduct(cookies["sofresh-admin-token"], slug || "")
  );

  // INITIAL PRODUCTS DATA
  const [product, setProduct] = useState<Product>({
    // const [product, setProduct] = useState<Product>({
    name: "",
    description: "",
    image: imageURL,
    price: "",
    category_id: 0,
    is_active: true,
    // extra_id: selectedOptions,
  });

  // TO ENSURE THE INITAIL DATA IS NOT UNDEFINED OR EMPTY STRING
  useEffect(() => {
    if (productData) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        // extra_id: productData.data.extra_id ?? selectedOptions,
        name: productData.data.name,
        description: productData.data.description,
        price: productData.data.price,
        is_active: productData.data.is_active,
        image: productData.data.image ?? imageURL,
        category_id: productData.data.categories?.[0]?.id,
      }));
    }

    //  [imageURL, productData, selectedOptions];
  }, [imageURL, productData]);

  // HANDLE PRODUCT IMAGE UPLOAD
  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "products",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.error(exception);
      navigate("/product");
    }
  };

  const handleProductImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setUploadLoading(true);

    try {
      const imageUrl = await handleFileUpload(file);
      setImageURL([imageUrl]);
      setProduct((prev) => ({
        ...prev,
        image: imageUrl,
      }));
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    } finally {
      setUploadLoading(false);
    }
  }; // END OF HANDLE PRODUCT IMAGE UPLOAD

  // DELETE SELECTED IMAGE
  const handleDeleteImage = () => {
    if (window.confirm("Do you want to delete selected image?")) {
      selectedImgRef?.current?.setAttribute("src", upload);
    } else {
      return;
    }
  };

  // FORM CONTROL FOR TEXT INPUTS
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!isOpen) return null;
    const { name, value } = event.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  // FORM CONTROL FOR SELECT OPTIONS INPUTS(product_categories: number)
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const val = Number(value);
    setProduct((prevProduct) => ({ ...prevProduct, category_id: val }));
  };

  // FORM CONTROL FOR SELECT OPTIONS INPUTS(is_active: string <true|false>)
  const handleSetActive = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "true") {
      setProduct((prevProduct) => ({ ...prevProduct, is_active: true }));
    } else {
      setProduct((prevProduct) => ({ ...prevProduct, is_active: false }));
    }
  };

  // FORM CONTROL FOR CHECKBOXES(extra_id: [])
  // const handleCheckboxChange = (option: number) => {
  //   const updatedOptions = selectedOptions.includes(option)
  //     ? selectedOptions.filter((extraID) => extraID !== option)
  //     : [...selectedOptions, option];
  //   setSelectedOptions(updatedOptions);

  //   setProduct((prevProduct) => ({ ...prevProduct, extra_id: updatedOptions }));
  // };

  // MUTATE FORM DATA BEFORE SUBMISSION
  const { mutate: updateProductMutation, isLoading } = useMutation(
    updateProductHandler,
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries(["products"]);
        toast.success("Product updated successfully");
      },
      onError: (e: string) => {
        console.error({ e });
        toast.error("Failed to update product");
      },
    }
  );

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      updateProductMutation({
        token: cookies["sofresh-admin-token"],
        data: product,
        slug,
      });
      await queryClient.invalidateQueries(["productData"]);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Update Product`}
      subTitle={`Upload image and enter information below to add extras`}
    >
      {isProductDataLoading ? (
        <div className="my-5 d-flex  align-items-center justify-content-center">
          <Oval height="40" width="40" color="#495057" visible={true} />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div
              className={`form-group pt-4 pb-2 ${styles.modalImageContainer}`}
            >
              <div>
                {uploadLoading || isProductDataLoading ? (
                  <div className="my-5 d-flex flex-column  align-items-center justify-content-center">
                    <Oval height="40" width="40" />
                  </div>
                ) : (
                  <img
                    ref={selectedImgRef}
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : productData?.data.image
                        ? productData?.data?.image[0]
                        : upload
                    }
                    alt="preview"
                    onClick={handleUploadBtnClick}
                    className={
                      productData?.data?.image || selectedFile
                        ? styles.imageContainer
                        : styles.uploadIcon
                    }
                  />
                )}
                <input
                  type="file"
                  id="uploadPlanImage"
                  accept="image/*"
                  ref={fileInputRef}
                  className="d-none"
                  onChange={handleProductImageUpload}
                />
              </div>

              <div className={styles.imageDisplayControls}>
                <div className="w-100 pt-2">
                  <p className={`${styles.modalImageText}`}>
                    <label htmlFor="uploadPlanImage">
                      <span className={styles.modalImageSpan}>
                        Click to upload{" "}
                      </span>
                    </label>
                    <span> a PNG, JPEG or WEBP image</span>
                    <span className={styles.modalImageText}>
                      {" "}
                      (image size should not exceed 400x400)
                    </span>
                  </p>
                </div>
                <div
                  className={`${styles.imageContolBtns} d-flex  align-items-center justify-content-between gap-3`}
                  style={{ width: "85%" }}
                >
                  <button
                    type="button"
                    onClick={handleDeleteImage}
                    className={`btn btn-sm ${styles.deleteImageBtn}`}
                    disabled={selectedImgRef?.current?.src === upload}
                  >
                    Delete Image
                  </button>
                  <label
                    htmlFor="uploadPlanImage"
                    className="btn btn-sm chooseNewImageBtn"
                  >
                    Pick New Image
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group py-2">
              <label className={styles.formLabel} htmlFor="name">
                Name
              </label>
              <input
                type="text"
                className={`form-control ${styles.formField}`}
                id="name"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                placeholder="Enter name of product Category"
                required
              />
            </div>

            <div className="form-group py-2">
              <label className={styles.formLabel} htmlFor="price">
                Price
              </label>
              <input
                type="text"
                className={`form-control ${styles.formField}`}
                id="price"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                placeholder="Enter price "
                inputMode="numeric"
                pattern="[0-9]+(\.[0-9]{1,2})?"
                required
              />
            </div>

            <div className="form-group py-2">
              <label className={styles.formLabel} htmlFor="category">
                Category:
              </label>
              <select
                className={`form-control ${styles.formField}`}
                id="category"
                name="category"
                value={product.category_id}
                onChange={handleSelectChange}
              >
                {product_category?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group py-2">
              <label className={styles.formLabel} htmlFor="status">
                Status:
              </label>
              <select
                className={`form-control ${styles.formField}`}
                id="status"
                name="status"
                value={product.is_active ? "true" : "false"}
                onChange={handleSetActive}
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>

            {/* <div className="form-group py-2">
              <label className={styles.formLabel}>Extras</label>
              <div className={styles.formCheckboxes}>
                {extrasOptions?.map((option) => (
                  <label
                    key={option.id}
                    className={`form-checkbox ${styles.formCheckbox}`}
                  >
                    <input
                      type="checkbox"
                      className={styles.formCheckboxIput}
                      checked={selectedOptions.includes(option.id)}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                    <span className={styles.formCheckboxLabel}>
                      {option.name}
                    </span>
                  </label>
                ))}
              </div>
            </div> */}

            <div className="form-group py-2">
              <label className={styles.formLabel} htmlFor="description">
                Description
              </label>
              <textarea
                className={`form-control ${styles.formField}`}
                id="description"
                name="description"
                value={product.description}
                onChange={handleInputChange}
                placeholder="Enter description"
                required
              />
            </div>
          </div>

          <div className={`pt-4 modal-footer ${styles.btnContainer}`}>
            <button
              type="submit"
              className={`btn form-control ${styles.btnColor}`}
            >
              {isLoading ? (
                <Oval
                  color="white"
                  wrapperClass="ml-2"
                  height={10}
                  width={10}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Modal>
  );
};
export default UpdateProductModal;
