import { AxiosError, AxiosResponse } from 'axios'
import { axiosClient } from '../utils/axiosClient'

/*=============================================
=              BLOGS HANDLERS TYPES           =
=============================================*/
export type BlogData = {
  title: string
  category?: number
  images: Array<string | undefined>
  article: any
}
export type CreateBlogData = {
  title: string
  blog_category_id?: number
  images: Array<string | undefined>
  article: any
}

export type BlogCategoryData = {
  title: string
  description: string
  is_active: boolean
}

export type CreateBlogPostProps = {
  token: string
  blogPost: CreateBlogData
}

export type CreateBlogPostResponse = {
  status: boolean
  message: string
}

export type CreateBlogCategoryProps = {
  token: string
  blogCategory: BlogCategoryData
}

export type CreateBlogCategoryResponse = {
  status: boolean
  message: string
}

type CategoryData = {
  description: string
  id: number
  images: string
  is_active: boolean
  meta: string
  slug: string
  title: string
}

export type GetBlogCategoriesResponse = {
  id: number
  title: string
  description: string
  slug: string
  is_active: boolean
  images: string
  meta: unknown
}

type GetBlogPostsOptions = {
  token: string
  page?: number
}

export type GetBlogPostsData = {
  id: number
  title: string
  article: string
  slug: string
  images: Array<string>
  is_active: boolean
  meta: unknown
  category: CategoryData
  created_at: Date | string
}

type GetBlogPostsMeta = {
  token: string
  current_page: number
  from: number
  last_page: number
  links: Array<unknown>
  path: string
  per_page: number
  to: number
  total: number
}

export type GetBlogPostsResponse = {
  meta: GetBlogPostsMeta
  data: Array<GetBlogPostsData>
  links: unknown
}

/*=============================================
=              BLOGS HANDLERS              =
=============================================*/
export const createBlogPost = async ({
  token,
  blogPost,
}: CreateBlogPostProps): Promise<CreateBlogPostResponse> => {
  try {
    const { data }: AxiosResponse<CreateBlogPostResponse> =
      await axiosClient.post(`admin/blogs`, blogPost, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
    return data
  } catch (error: unknown) {
    console.error(error)
    throw new Error('An error occurred. Please try again.')
  }
}

// export const getBlogPosts = async ({ token }: { token: string }) => {
//   // }): Promise<Array<GetBlogPostsResponse>> => {
//   try {
//     // const { data } =
//     const { data } =
//       // const { data }: AxiosResponse<{ data: Array<GetBlogPostsResponse> }> =
//       await axiosClient.get(`admin/blogs`, {
//         headers: {
//           'Content-Type': 'application/json',
//           authorization: `Bearer ${token}`,
//         },
//       })
//     return data
//   } catch (error: unknown) {
//     const axiosError = error as AxiosError
//     console.error(axiosError)
//     throw new Error('An error occurred. Please try again.')
//   }
// }

export const getBlogPosts = async ({
  token,
  page = 1,
}: GetBlogPostsOptions): Promise<GetBlogPostsResponse> => {
  try {
    const response: AxiosResponse<GetBlogPostsResponse> = await axiosClient.get(
      `admin/blogs?page=${page}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error: unknown) {
    const axiosError = error as AxiosError
    console.error(axiosError)
    throw new Error('An error occurred. Please try again.')
  }
}

// export const getBlogPosts = async ({
//   token,
//   blogPost,
// }: CreateBlogPostProps): Promise<CreateBlogPostResponse> => {
//   try {
//     const { data } = await axiosClient.get(`admin/blogs`, {
//       headers: {
//         'Content-Type': 'application/json',
//         authorization: `Bearer ${token}`,
//       },
//     })
//     return data
//   } catch (error: unknown) {
//     console.error(error)
//     throw new Error('An error occured. Please try again.')
//   }
// }
/*=====    End of BLOGS HANDLERS    ======*/

/*=============================================
=          BLOGS CTAEGORIES HANDLERS          =
=============================================*/
export const createBlogCategory = async ({
  token,
  blogCategory,
}: CreateBlogCategoryProps): Promise<CreateBlogCategoryResponse> => {
  try {
    const { data }: AxiosResponse<CreateBlogCategoryResponse> =
      await axiosClient.post(`admin/blog-categories`, blogCategory, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
    return data
  } catch (error) {
    console.error(error)
    throw new Error('An error occurred. Please try again.')
  }
}

export const getBlogCategories = async ({
  token,
}: {
  token: string
}): Promise<Array<GetBlogCategoriesResponse>> => {
  try {
    const { data }: AxiosResponse<{ data: Array<GetBlogCategoriesResponse> }> =
      await axiosClient.get(`admin/blog-categories`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
    return data.data
  } catch (error: unknown) {
    const axiosError = error as AxiosError
    console.error(axiosError)
    throw new Error('An error occurred. Please try again.')
  }
}
/*=====    End of BLOG CTAEGORIES HANDLERS    ======*/
