import { axiosClient } from '../utils/axiosClient'

export const getAdminOrders = async ({ token, role }: any) => {
  try {
    const response = await axiosClient.get(
      `/${role === 'admin' ? 'admin' : 'subadmin'}/users/orders?page_size=20`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}

export const getSingleOrder = async ({ token, role, reference }: any) => {
  try {
    const response = await axiosClient.get(
      `/${role === 'admin' ? 'admin' : 'subadmin'}/users/orders/${reference}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}

export const updateOrderRelatedStore = async ({
  token,
  role,
  reference,
  store_id,
}: any) => {
  try {
    const response = await axiosClient.put(
      `/${role === 'admin' ? 'admin' : 'subadmin'}/users/orders/${reference}`,
      store_id,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}
