// REACT DEFAULTS
import { ChangeEvent, FC, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useMutation, useQuery, useQueryClient } from 'react-query'

// COMPONENTS, LIBs & UTILS
import Button from '../../../components/button/button'
import { getStores } from '../../../handlers/storeHandler'

// IMAGES & ICONS
// import { IoMdClose } from 'react-icons/io'
import { toast } from '../../../components/toast/toast'

// STYLES
import './DeliveryPoints.css'
import { createDelieryPoints } from '../../../handlers/deliveryHandlers'
import Modal from '../../../components/Modal'

const CreateDeliveryPointModal: FC<ICreateDeliveryPointModalProps> = ({
  onClose,
  isOpen,
}) => {
  const queryClient = useQueryClient()
  const [cookies] = useCookies(['sofresh-admin-token'])
  const navigate = useNavigate()

  // GET STORE/OUTLET SELECT DATA
  const { data: storesData } = useQuery('categories', () =>
    getStores(cookies['sofresh-admin-token'])
  )
  // console.log('storesData: ', storesData)

  // STORE INITIAL VALUE STATE OBJECT
  const [deliveryPointsFormData, setDeliveryPointsFormData] =
    useState<ICreateDeliveryPoint>({
      store_id: 1,
      name: '',
      latitude: '',
      longitude: '',
      price: '',
      is_active: true,
    })

  // HANDLE FORM CONTROLLED INPUT FIELDS
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setDeliveryPointsFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  // FORM CONTROL FOR SELECT OPTIONS INPUTS(store_id: number)
  const handleStoreIdSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    const val = Number(value)
    // console.log('Store_ID: ', val)
    setDeliveryPointsFormData(prev => ({
      ...prev,
      store_id: val,
    }))
  }

  // MUTATE FORM DATA BEFORE SUBMISSION
  const { mutate: createDeliveryPointMutation, isLoading: isSubmitting } =
    useMutation(createDelieryPoints, {
      onSuccess: async res => {
        toast.success('Delivery point created successfully')
        await queryClient.invalidateQueries(['delivery-points'])
      },
      onError: (e: string) => {
        console.error({ e })
        navigate('/deliverypoints')
        toast.error(e)
      },
    })

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // onSubmit(product)
    // console.log('deliveryPointsFormData: ', deliveryPointsFormData)
    try {
      createDeliveryPointMutation({
        token: cookies['sofresh-admin-token'],
        data: deliveryPointsFormData,
      })

      toast.success('Delivery point created successfully')
      await queryClient.invalidateQueries(['delivery-points'])
      onClose()
    } catch (error) {
      console.error(error)
      toast.error('Failed to create delivery point')
    }
  }

  return (
    <Modal onClose={onClose} title='Create Delivery Point' isOpen={isOpen}>
      <div className='storeModal-body'>
        <form onSubmit={handleSubmit}>
          <div className='form-group name py-2'>
            <label htmlFor='name' className='form-label'>
              Name
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='name'
              name='name'
              placeholder='Enter Store Name'
              value={deliveryPointsFormData.name}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group longitude py-2'>
            <label htmlFor='longitude' className='form-label'>
              Longitude
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='longitude'
              name='longitude'
              placeholder='Enter Location'
              value={deliveryPointsFormData.longitude}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group latitude py-2'>
            <label htmlFor='latitude' className='form-label'>
              Latitude
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='latitude'
              name='latitude'
              placeholder='Enter Latitude'
              value={deliveryPointsFormData.latitude}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group price py-2'>
            <label htmlFor='price' className='form-label'>
              Price
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='price'
              name='price'
              placeholder='Enter Price'
              inputMode='numeric'
              pattern='[0-9]+(\.[0-9]{1,2})?'
              value={deliveryPointsFormData.price}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group py-2'>
            <label className='form-label' htmlFor='outlet'>
              Outlet
            </label>
            <select
              className='form-control form-field'
              id='outlet'
              name='outlet'
              value={deliveryPointsFormData.store_id}
              onChange={handleStoreIdSelectChange}>
              {storesData?.map((store: IGetStoresData) => (
                <option key={store.id} value={store.id}>
                  {store.store_name}
                </option>
              ))}
            </select>
          </div>

          <div className=' text-center mt-3 flex justify-center'>
            <Button formControl type='submit' loading={isSubmitting}>
              Create Delivery Point
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default CreateDeliveryPointModal
