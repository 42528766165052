import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  GetBlogCategoriesResponse,
  getBlogCategories,
} from "../../../../handlers/blogsHandlers";
import { useCookies } from "react-cookie";
import { Oval } from "react-loader-spinner";
import Table from "../../../../components/table/Table";
import styles from "./BlogCategories.module.css";

const BlogCategories = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);

  const { data: blogCategories, isLoading: isBlogCategoriesLoading } = useQuery(
    "blog-categories",
    () => getBlogCategories({ token: cookies["sofresh-admin-token"] })
  );

  const columns = [
    {
      name: "Blog Category ID",
      selector: (row: GetBlogCategoriesResponse) => row.id,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row: GetBlogCategoriesResponse) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row: GetBlogCategoriesResponse) => row.description,
      sortable: true,
    },
    {
      name: "Available",
      selector: (row: GetBlogCategoriesResponse) => (
        <span
          className={
            row.is_active
              ? "badge bg-success outline-0 border-0"
              : "badge bg-danger outline-0 border-0"
          }>
          {row.is_active ? <span>Yes</span> : <span>No</span>}
        </span>
      ),
      sortable: true,
    },
  ];

  const handleOpenCreateBlogCategoriesPage = () => {
    navigate("/marketing/blog-categories/create");
  };

  return (
    <main className={`content ${styles.blogPostBackground}`}>
      <section className="container-fluid">
        <div className="row px-0">
          <div className="w-100 col-md-12 d-flex  align-items-center justify-content-between flex-wrap gap-4 py-4">
            <h1 className="h2 m-0">Blog Categories</h1>

            <button
              onClick={handleOpenCreateBlogCategoriesPage}
              className="btn btn-warning">
              Create Blog Categories <i className="bi bi-bag-plus-fill"></i>
            </button>
          </div>
        </div>

        <div className="row px-0">
          {isBlogCategoriesLoading ? (
            <div className="my-5 w-100 d-flex  align-items-center justify-content-center">
              <Oval height="40" width="40" color="#495057" visible={true} />
            </div>
          ) : (
            blogCategories && (
              <div>
                <Table data={blogCategories} columns={columns} />
              </div>
            )
          )}
        </div>
      </section>
    </main>
  );
};

export default BlogCategories;
