import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";
import Table from "../../../components/table/Table";
import { getNavigationButton } from "../../../handlers/navigationHandler";
import { Oval } from "react-loader-spinner";

interface IData {
  created_at: string;
  title: string;
  url: string;
  is_active: string;
  id: string;
}

const NavigationButton = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const { data, isLoading } = useQuery("sub-admin", () =>
    getNavigationButton(cookies["sofresh-admin-token"])
  );

  const columns = [
    {
      name: "Date Created",
      selector: (row: IData) => {
        const date = new Date(row.created_at);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      },
      sortable: true,
    },
    {
      name: "Title",
      selector: (row: IData) => row.title,
      sortable: true,
    },
    {
      name: "Url",
      selector: (row: IData) => row.url,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: IData) =>
        row.is_active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">Inactive</span>
        ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row: IData) => (
        <div className="flex align-items-center">
          <button
            onClick={() => navigate(`/navigation-buttons/${row.id}/edit`)}
            style={{
              background: "transparent",
              outline: "transparent",
              border: "transparent",
            }}
          >
            <i className="bi bi-pencil-square me-2"></i>
          </button>
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex items-center justify-content-between">
              <h1 className="h3">All Buttons</h1>
              <Link
                to="/navigation-buttons/create"
                className="btn btn-lg btn-warning"
              >
                Create New Button
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading ? (
                  <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  <Table data={data} columns={columns} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NavigationButton;
