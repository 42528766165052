import { axiosClient } from '../utils/axiosClient'

export const getStores = async (token: string) => {
  try {
    const response = await axiosClient.get(`/admin/stores`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return response.data.data
  } catch (error: any) {
    console.error({ error })

    throw new Error('An error occured. Please try again.')
  }
}

export const getSingleStore = async ({ token, slug }: any) => {
  try {
    const response = await axiosClient.get(`/admin/stores/${slug}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return response.data.data
  } catch (error: any) {
    console.log({ error })

    throw new Error('An error occured. Please try again.')
  }
}

export const createStore = async ({ data, token }: any) => {
  try {
    const response = await axiosClient.post(`/admin/stores`, data, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error: any) {
    if (error?.response?.data?.error?.sub_admin) {
      throw error?.response?.data?.error.sub_admin[0]
    }
    if (error?.response?.data?.error?.email) {
      throw error?.response?.data?.error.email[0]
    }
    if (error?.response?.data?.error?.image) {
      throw error?.response?.data?.error.image[0]
    }
    throw new Error('An error occured. Please try again.')
  }
}

export const updateStore = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.put(`/admin/stores/${slug}`, data, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error: any) {
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0]
    }
    if (error?.response?.data?.error?.sub_admin) {
      throw error?.response?.data?.error.sub_admin[0]
    }
    if (error?.response?.data?.error?.email) {
      throw error?.response?.data?.error.email[0]
    }
    if (error?.response?.data?.error?.image) {
      throw error?.response?.data?.error.image[0]
    }
    throw new Error('An error occured. Please try again.')
  }
}
