import { axiosClient } from '../utils/axiosClient'

export const loginUser = async (data: any) => {
  try {
    const response = await axiosClient.post(`/auth/login`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}
