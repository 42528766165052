import { FormEvent, ChangeEvent, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useMutation, useQueryClient } from 'react-query'

// COMPONENTS, ICONS & IMAGES
import Modal from '../../../components/Modal'
import { Oval } from 'react-loader-spinner'
import ReactS3Client from 'react-aws-s3-typescript'
import { s3Config } from '../../../utils/s3Config'
import { toast } from '../../../components/toast/toast'
import { createPlan } from '../../../handlers/productHandler'
import upload from '../../../assets/uploadPNG.png'

// STYLES
import styles from './Plans.module.css'

interface ICreatePlanModalProps {
  onClose: () => void
  isOpen: boolean
}

interface IPlanData {
  image: (string | undefined)[]
  name: string
  email: string
  description: string
}

const CreatePlanModal = ({ isOpen, onClose }: ICreatePlanModalProps) => {
  const queryClient = useQueryClient()
  const [cookies] = useCookies(['sofresh-admin-token'])
  const navigate = useNavigate()

  const [uploadLoading, setUploadLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [images, setImages] = useState<(string | undefined)[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)

  // INITIAL PLANS DATA
  const [plan, setPlan] = useState<IPlanData>({
    image: images,
    name: '',
    email: '',
    description: '',
  })
  // console.log('PlanCAT: ', plan_category)

  // HANDLE PLAN IMAGE UPLOAD
  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'plans',
    })
    try {
      const res = await s3.uploadFile(file)
      return res.location
    } catch (exception) {
      console.error(exception)
    }
  }

  const handlePlanImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    setSelectedFile(file)
    setUploadLoading(true)

    try {
      const imageUrl = await handleFileUpload(file)
      setImages([imageUrl])
      setPlan(prevPlan => ({
        ...prevPlan,
        image: [imageUrl],
      }))
    } catch (error) {
      console.error(error)
      toast.error('Failed to upload image')
    } finally {
      setUploadLoading(false)
    }
  } // END OF HANDLE PLAN IMAGE UPLOAD

  // FORM CONTROL FOR TEXT INPUTS
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // if (!isOpen) return null
    const { name, value } = event.target
    setPlan(prevPlan => ({ ...prevPlan, [name]: value }))
  }

  // HANDLE PLANS MUTATION
  const { mutate: createPlanMutation, isLoading } = useMutation(createPlan, {
    onSuccess: async res => {
      console.log({ res })
      toast.success('Plan created successfully')
      await queryClient.invalidateQueries(['plans'])
      navigate('/plans')
    },
    onError: (e: string) => {
      console.error({ e })
      toast.error('Failed to create plan')
    },
  })

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // onSubmit(plan)
    // console.log('PLAN: ', plan)

    const data = {
      ...plan,
      image: images,
    }

    try {
      createPlanMutation({
        token: cookies['sofresh-admin-token'],
        data,
      })
      await queryClient.invalidateQueries(['plans'])
      onClose()
    } catch (error) {
      console.error(error)
      toast.error('Failed to create plan')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Add New Plan`}
      subTitle={`Upload image and enter information below to add new plan`}>
      <form onSubmit={handleSubmit}>
        <div className='modal-body'>
          <div className={`form-group py-2 ${styles.modalImageContainer}`}>
            <div>
              {uploadLoading ? (
                <Oval wrapperClass='ml-2' height={20} width={20} />
              ) : (
                <img
                  src={
                    selectedFile ? URL.createObjectURL(selectedFile) : upload
                  }
                  alt='preview'
                  onClick={handleUploadBtnClick}
                  className={
                    selectedFile ? styles.imageContainer : styles.uploadIcon
                  }
                />
              )}
              <input
                type='file'
                id='uploadPlanImage'
                accept='image/*'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handlePlanImageUpload}
              />
            </div>
            <p className={styles.modalImageText}>
              <label htmlFor='uploadPlanImage'>
                <span className={styles.modalImageSpan}>Click to upload </span>{' '}
              </label>
              or drag and drop image
            </p>
            <p className={styles.modalImageText}>
              PNG, JPEG (image size should not exceed 400x400)
            </p>
          </div>

          <div className='form-group py-2'>
            <label className={styles.formLabel} htmlFor='name'>
              Plan Name
            </label>
            <input
              type='text'
              className={`${styles.formField} form-control`}
              id='name'
              name='name'
              value={plan.name}
              onChange={handleInputChange}
              placeholder='Enter name of plan'
              required
            />
          </div>

          <div className='form-group py-2'>
            <label className={styles.formLabel} htmlFor='description'>
              Email
            </label>
            <input
              type='email'
              className={`${styles.formField} form-control`}
              id='email'
              name='email'
              value={plan.email}
              onChange={handleInputChange}
              placeholder='Enter name of plan'
              required
            />
          </div>

          <div className='form-group py-2'>
            <label className={styles.formLabel} htmlFor='description'>
              Description
            </label>
            <textarea
              className={`${styles.formField} form-control`}
              id='description'
              name='description'
              value={plan.description}
              onChange={handleInputChange}
              placeholder='Enter description'
              required
            />
          </div>
        </div>

        <div className={`pt-4 btn-container ${styles.modalFooter}`}>
          <button
            type='submit'
            className={`btn form-control ${styles.btnColor}`}>
            {isLoading ? (
              <Oval color='white' wrapperClass='ml-2' height={10} width={10} />
            ) : (
              'Create Plan'
            )}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default CreatePlanModal
