import React from 'react'
import { useCookies } from 'react-cookie'

interface IAppContext {
  children: any
}

interface IAuthContext {
  user?: any
  isAuthenticated: boolean
  handleAuthState: (data: any) => void
  token?: any
}

export const AuthContext = React.createContext<IAuthContext>({
  user: null,
  isAuthenticated: false,
  handleAuthState: (data: any) => {},
  token: null,
})

const AppContext: React.FC<IAppContext> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'sofresh-admin-token',
    'sofresh-admin-user',
  ])
  const [state, setState] = React.useState({
    isAuthenticated: false,
    user: null,
    token: null,
  })

  const handleAuthState = (data: any) => {
    if (data) {
      setState({
        isAuthenticated: true,
        user: data.user,
        token: data.token,
      })
      if (!cookies['sofresh-admin-token']) {
        setCookie('sofresh-admin-token', data.token)
        setCookie('sofresh-admin-user', data.user)
      }
      return
    } else {
      setState({
        isAuthenticated: false,
        user: null,
        token: null,
      })
      removeCookie('sofresh-admin-token')
      removeCookie('sofresh-admin-user')
    }
  }
  return (
    <AuthContext.Provider value={{ ...state, handleAuthState }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AppContext
