import { Link } from "react-router-dom";
// import locationimg from "../../../assets/locatorimg.svg";
import StoreLocatorItem from "../../../components/storelocator/storelocator";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";
import { Oval } from "react-loader-spinner";
import { getLocations } from "../../../handlers/locationHandlers";

const StoreLocator = () => {
  const [cookies] = useCookies(["sofresh-admin-token"]);

  const { data: locationsData, isLoading } = useQuery("locations", () =>
    getLocations<IStoreLocationData[]>(cookies["sofresh-admin-token"])
  );

  // const storeLocations = [
  //   {
  //     locationImage: locationimg,
  //     title: "Fitness Central Oniru Outlet",
  //     address:
  //       "Adekola Balogun Street Perchstone & Graeys Close, Lekki Phase 1, Lagos",
  //     delivery: "Delivery",
  //     phoneNumber: "08062868430",
  //   },
  //   {
  //     locationImage: locationimg,
  //     title: "Fitness Central Oniru Outlet",
  //     address:
  //       "Adekola Balogun Street Perchstone & Graeys Close, Lekki Phase 1, Lagos",
  //     delivery: "Pickup",
  //     phoneNumber: "08062868430",
  //   },
  // ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex items-center justify-content-between">
              <h1 className="h3">Store Locator</h1>
              <Link to={"/add-location"} className="btn btn-lg btn-warning">
                Add New Store
              </Link>
            </div>
          </div>
        </div>
        <div className="my-5">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control-lg form-control"
                      placeholder="Filter by Location"
                      aria-label="Search"
                    />
                  </div>
                </div>
                <div className="col">
                  <select className="form-select form-select-lg">
                    <option defaultValue={""}>Filter by Date Created</option>
                    <option value="1">Latest</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
              <Oval height="40" width="40" color="#495057" visible={true} />
            </div>
          ) : (
            <div className="row justify-content-between store-locator">
              {locationsData?.map((store, index) => (
                <StoreLocatorItem
                  key={index}
                  image={store.image}
                  name={store.name}
                  address={store.address}
                  delivery_option={store.delivery_option}
                  phone_no={store.phone_no}
                  slug={store.slug}
                  is_active={store.is_active}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default StoreLocator;
