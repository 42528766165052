import React from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Form, Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { s3Config } from "../../../utils/s3Config";
import ReactS3Client from "react-aws-s3-typescript";
import Button from "../../../components/button/button";
import Input from "../../../components/input/input";
import { createPlanValidationSchema } from "../../../utils/validation";
import { useCookies } from "react-cookie";
import { getSinglePlan, updatePlan } from "../../../handlers/productHandler";
import { toast } from "../../../components/toast/toast";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout";

const UpdatePlan = () => {
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const { data: planData, isLoading: isPlanDataLoading } = useQuery(
    `${slug}-plan`,
    () => getSinglePlan({ token: cookies["sofresh-admin-token"], slug })
  );
  // console.log('planData: ', planData)

  const { mutate: updatePlanMutation, isLoading } = useMutation(updatePlan, {
    onSuccess: async res => {
      console.log({ res });
      toast.success("Plan updated successfully");
      await queryClient.invalidateQueries(["plans"]);
      navigate("/plans");
    },
    onError: (e: string) => {
      console.error({ e });
      toast.error(e);
    },
  });

  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [images, setImages] = React.useState<any[]>([]);

  const initialValues = {
    name: (planData && planData[0]?.name) || "",
    description: (planData && planData[0]?.description) || "",
    email: (planData && planData[0]?.email) || "",
  };

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "products",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleFileInput = async (e: any) => {
    const files = e.target.files;
    setUploadLoading(true);
    const fileLocations = [];

    for (let i = 0; i < files.length; i++) {
      const loc = await handleFileUpload(files[i]);
      fileLocations.push(loc);
    }
    setUploadLoading(false);
    setImages(fileLocations);
    console.log({ fileLocations });
  };

  const handleCreate = (values: any) => {
    const data = {
      ...values,
      image: images.length > 0 ? images : planData && planData[0]?.image,
    };
    updatePlanMutation({
      token: cookies["sofresh-admin-token"],
      data,
      slug,
    });
  };

  return (
    <DashboardLayout>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12">
              <h1 className="h3 mb-3">Update Plan</h1>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="card">
                <div className="card-body">
                  {isPlanDataLoading ? (
                    <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                      <Oval
                        height="40"
                        width="40"
                        color="#495057"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="m-sm-4">
                      <Formik
                        initialValues={initialValues}
                        onSubmit={values => handleCreate(values)}
                        validationSchema={createPlanValidationSchema}
                        enableReinitialize={true}>
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          setFieldValue,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label">
                                Meal Plan Name
                              </label>
                              <Input
                                className="form-control form-control-lg"
                                type="text"
                                name="name"
                                placeholder="Enter product name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Meal Plan Email
                              </label>
                              <Input
                                className="form-control form-control-lg"
                                type="text"
                                name="email"
                                placeholder="Enter email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <Input
                                className="form-control form-control-lg"
                                type="text"
                                placeholder="Enter a description"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Plan Images
                              </label>
                              <div className="flex align-items-center ">
                                <input
                                  className="form-control form-control-sm"
                                  id="formFileSm"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileInput}
                                  multiple={true}
                                />
                                {uploadLoading && (
                                  <Oval
                                    wrapperClass="ml-2"
                                    height={20}
                                    width={20}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="text-center mt-3 flex justify-center">
                              <Button
                                type="submit"
                                loading={isLoading}
                                disabled={uploadLoading}>
                                Update Plan
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default UpdatePlan;
