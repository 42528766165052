import { useQuery } from "react-query";
import { getSingleOrder } from "../../../handlers/orderHandler";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { formatToNigerianNaira } from "../../../utils/helper";
import { Oval } from "react-loader-spinner";

const SingleOrderView = () => {
  const { reference } = useParams();
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);
  const { data, isLoading } = useQuery(`order-${reference}`, () =>
    getSingleOrder({
      token: cookies["sofresh-admin-token"],
      role: cookies["sofresh-admin-user"]?.role,
      reference: reference,
    })
  );
  // console.log('DATA: ', data)
  // console.log('DATASTAT: ', data?.meta?.recipient)
  // console.log('DATA: ', data?.order_details)

  return (
    <main className="content">
      {isLoading ? (
        <div className="my-8 w-full h-full flex align-items-center justify-center">
          <Oval height="40" width="40" />
        </div>
      ) : (
        <>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <h1 className="h3 mb-3">Order Summary</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive-lg">
                      <table className="table table-hover my-0">
                        <thead>
                          <tr>
                            <th>Order ID</th>
                            <th>Ordered Items Cost</th>
                            <th>Delivery Cost</th>
                            <th>Total Cost</th>
                            <th>Order Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>#{data?.reference}</td>
                            <td>
                              {data?.total_amount &&
                                formatToNigerianNaira(data?.total_amount)}
                            </td>
                            <td>
                              {data?.delivery_fee
                                ? formatToNigerianNaira(data?.delivery_fee)
                                : formatToNigerianNaira(0)}
                            </td>
                            <td>
                              {data?.total_amount &&
                                formatToNigerianNaira(data?.total_amount)}
                            </td>

                            <td
                              className={
                                data?.order_status === "completed"
                                  ? "text-success"
                                  : data?.order_status === "pending"
                                  ? "text-warning"
                                  : "text-danger"
                              }>
                              {data?.order_status}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* BEGINING OF PRODUCTS TABLE */}
          {data?.order_details?.product && (
            <div className="container-fluid p-0 mt-2">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="h3 mb-3">Ordered Products</h1>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive-lg">
                        <table className="table table-hover my-0">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Cost of Product</th>
                              <th>Extras</th>
                              <th>Cost of Extra</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.order_details?.map(
                              (item: any, idx: number) =>
                                item?.product?.name && (
                                  <tr key={idx}>
                                    <td>
                                      {`${item?.product?.name} x ${item?.quantity}`}
                                    </td>

                                    {item?.product && (
                                      <>
                                        <td>
                                          {/* COST OF PRODUCT */}
                                          {item?.total_amount &&
                                            formatToNigerianNaira(
                                              item?.total_amount
                                            )}
                                        </td>
                                        <td>
                                          {item?.extras?.map(
                                            (extra: any, index: number) =>
                                              item?.extras.length < 1 ? (
                                                <span>No Extras</span>
                                              ) : (
                                                <span key={extra?.extra?.name}>
                                                  {`${extra?.extra?.name} x ${
                                                    extra?.quantity
                                                  }${
                                                    index ===
                                                    item?.extras.length - 1
                                                      ? "."
                                                      : ", "
                                                  }`}
                                                  {/* ABOVE LOGIC: adds a , to the end of everey extra and a . at the end of the last extra in the array or if its the only extra in the array */}
                                                </span>
                                              )
                                          )}
                                        </td>

                                        <td>
                                          {/* EXTRAS TOTAL */}
                                          {formatToNigerianNaira(
                                            item?.extras?.reduce(
                                              (
                                                accumulator: number,
                                                extra: any
                                              ) =>
                                                accumulator +
                                                Number(extra?.total_amount),
                                              0
                                            )
                                          )}
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* BEGINING OF PLANS TABLE */}
          {data?.order_details?.plan_type && (
            <div className="container-fluid p-0 mt-2">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="h3 mb-3">Ordered Plans</h1>
                </div>
              </div>

              {data?.order_details?.length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-lg">
                          <table className="table table-hover my-0">
                            <thead>
                              <tr>
                                <th>Plan Name</th>
                                <th>Plan Type Name</th>
                                <th>Meal Preference</th>
                                <th>Duartion</th>
                                <th>Total cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.order_details?.map(
                                (item: any, idx: number) =>
                                  item?.plan_type?.name && (
                                    <tr key={idx}>
                                      {item?.plan_type && (
                                        <>
                                          <td>{`${item?.plan_type?.plan?.name}`}</td>
                                          <td>{`${item?.plan_type?.name}`}</td>
                                          <td>
                                            {item?.preference
                                              ? item?.preference
                                              : "None"}
                                          </td>
                                          <td>{`${item?.duration} Day(s)`}</td>
                                        </>
                                      )}

                                      <td>
                                        {/* ORDER TOTAL */}
                                        {item?.total_amount &&
                                          formatToNigerianNaira(
                                            item?.total_amount
                                          )}
                                      </td>
                                    </tr>
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="container-fluid p-0 mt-2">
            <div className="row">
              <div className="col-md-12">
                <h1 className="h3 mb-3">Customer Information</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive-lg">
                      <table className="table table-hover my-0">
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            <th>Email Address</th>
                            <th>Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data?.user?.name}</td>
                            <td>{data?.user?.email}</td>
                            <td>{data?.user?.phone_no}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data?.meta?.recipient?.recipient_name && (
            <div className="container-fluid p-0 mt-2">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="h3 mb-3">Order Recipient</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive-lg">
                        <table className="table table-hover my-0">
                          <thead>
                            <tr>
                              <th>Recipient's Name</th>
                              <th>Recipient's Phone Number</th>
                              {data?.delivery_address && (
                                <th>Recipient's Address</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{data?.meta.recipient.recipient_name}</td>
                              <td>
                                {data?.meta.recipient.recipient_mobile_number}
                              </td>
                              {data?.delivery_address && (
                                <td>
                                  {data?.delivery_address
                                    ? data?.delivery_address
                                    : data?.user?.address}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="container-fluid p-0 mt-2">
            <div className="row">
              <div className="col-md-12">
                <h1 className="h3 mb-3">Delivery Information</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive-lg">
                      <table className="table table-hover my-0">
                        <thead>
                          <tr>
                            <th>Delivery Service</th>
                            {data?.delivery_type === "pickup" && (
                              <th>Pickup Outlet </th>
                            )}
                            <th>Delivery Fee</th>
                            {data?.delivery_fee && <th>Delivery Address</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data?.delivery_type}</td>
                            <td>
                              {data?.delivery_fee
                                ? formatToNigerianNaira(data?.delivery_fee)
                                : formatToNigerianNaira(0)}
                            </td>
                            {data?.delivery_type === "pickup" && (
                              <td>{data.store.store_name ?? "N/A"}</td>
                            )}
                            {data?.delivery_fee && (
                              <td>{data?.delivery_address}</td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid p-0 mt-2">
            <div className="row">
              <div className="col-md-12">
                <h1 className="h3 mb-3">Store Information</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table table-hover my-0">
                      <thead>
                        <tr>
                          <th>Store Branch</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{data?.store?.store_name ?? "N/A"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default SingleOrderView;
