import { axiosClient } from '../utils/axiosClient'

type UserAccountsMeta = {
  allergies: string
  expectant_mum: string
}

export type UserAccountData = {
  id: number | null
  name: string
  gender: string
  phone_no: string
  email: string
  role: string
  is_active: boolean
  store: string
  meta: UserAccountsMeta
}

export type SingleUserAccountProps = {
  token: string
  id: string
}

export type UpdateSingleUserAccountHandlerProps = {
  token: string
  userID: string
  userAccountDetails: UserAccountData
}

type SingleUserAccountResponse = {
  status: boolean
  message: string
}

export const getUserAccounts = async (
  token: string
): Promise<Array<UserAccountData>> => {
  try {
    const { data } = await axiosClient.get(`admin/users`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return data?.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}

export const getSingleUserAccount = async ({
  token,
  id,
}: SingleUserAccountProps): Promise<UserAccountData> => {
  try {
    const { data } = await axiosClient.get(`admin/users/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })

    return data?.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}

export const UpdateSingleUserAccountHandler = async ({
  token,
  userID,
  userAccountDetails,
}: UpdateSingleUserAccountHandlerProps): Promise<SingleUserAccountResponse> => {
  try {
    const res = await axiosClient.put(
      `admin/reviews/${userID}`,
      userAccountDetails,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )

    return res?.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}
