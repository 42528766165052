import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  GetBlogPostsData,
  getBlogPosts,
} from "../../../../handlers/blogsHandlers";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import placeholderImage from "../../../../assets/blog_sm.png";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import styles from "./Blogs.module.css";
import { scrollToTop } from "../../../../utils/helper";

const Blog = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [prevButtonHovered, setPrevButtonHovered] = useState(false);
  const [nextButtonHovered, setNextButtonHovered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: blogPosts, isLoading: isBlogPostsLoading } = useQuery(
    ["blog-posts", currentPage],
    () =>
      getBlogPosts({
        token: cookies["sofresh-admin-token"],
        page: currentPage,
      })
  );

  const itemsPerPage = blogPosts?.meta?.per_page ?? 10;
  const totalItems = blogPosts?.meta?.total ?? 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleOpenCreateBlogPage = () => {
    navigate("/marketing/blogs/create");
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  return (
    <main className={`content ${styles.blogPostBackground}`}>
      <section className="container-fluid p-0">
        <div className="row px-0">
          <div className="w-100 col-md-12 d-flex  align-items-center justify-content-between flex-wrap gap-4 py-4">
            <h1 className="h2 m-0">Blog Post</h1>

            <button
              onClick={handleOpenCreateBlogPage}
              className="btn btn-warning"
            >
              Create Blog Post <i className="bi bi-bag-plus-fill"></i>
            </button>
          </div>
        </div>

        {isBlogPostsLoading ? (
          <div className="my-5 w-100 d-flex  align-items-center justify-content-center">
            <Oval height="40" width="40" color="#495057" visible={true} />
          </div>
        ) : (
          <>
            <div
              className="row align-items-start"
              // className='row row-cols-2 align-items-start'
              style={{ minBlockSize: "50dvh" }}
            >
              {blogPosts?.data &&
                blogPosts?.data?.map((blog: GetBlogPostsData) => (
                  <figure
                    className="col col-md-6 d-flex flex-column flex-md-row  align-items-center justify-content-start gap-2 gap-md-3 gap-xl-4 my-4"
                    key={blog.id}
                  >
                    <div className={styles.imageContainer}>
                      <img
                        src={blog.images ? blog.images[0] : placeholderImage}
                        alt="blog banner"
                        className="rounded-3"
                        style={{
                          width: "inherit",
                          height: "inherit",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    <figcaption
                      className="w-100 d-flex flex-column align-items-start justify-content-around"
                      style={{ height: "130px" }}
                    >
                      <h4 className={styles.blogPostTitle}>{blog.title}</h4>
                      <p className={styles.blogPostDetails}>
                        {blog.category ? blog.category?.title : "Blog Category"}
                      </p>
                      <p className={styles.blogPostDetails}>{`Published on ${
                        blog.created_at
                          ? blog.created_at
                          : new Date().toUTCString()
                      }`}</p>
                    </figcaption>
                  </figure>
                ))}
            </div>
          </>
        )}

        <div className="row p-0">
          <div
            className="w-100 col-md-12 d-flex  align-items-center justify-content-between"
            style={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            <button
              disabled={currentPage === 1 || isBlogPostsLoading}
              onClick={() => handlePageChange(currentPage - 1)}
              className={`btn btn-link px-4 py-1 d-flex  align-items-center justify-contents-center gap-2 text-decoration-none ${
                currentPage === 1
                  ? `bg-white text-muted`
                  : prevButtonHovered
                  ? "bg-success text-white"
                  : "text-dark"
              }`}
              style={{
                borderRadius: "6px",
                border: "1px solid #D0D5DD",
                transition: "all 300ms ease-in",
              }}
              onMouseEnter={() => setPrevButtonHovered(true)}
              onMouseLeave={() => setPrevButtonHovered(false)}
            >
              <CgArrowLongLeft />
              <span>Previous</span>
            </button>
            <span className="my-5 w-100 d-flex  align-items-center justify-content-center">
              {`Page ${currentPage} of `}
              {totalPages ? (
                totalPages
              ) : (
                <span className="d-inline">
                  <Oval height="15" width="15" color="#495057" visible={true} />
                </span>
              )}
            </span>
            <button
              disabled={currentPage === totalPages || isBlogPostsLoading}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`btn btn-link px-4 py-1 d-flex  align-items-center justify-contents-center gap-2 text-decoration-none ${
                currentPage === totalPages
                  ? `bg-white text-muted`
                  : nextButtonHovered
                  ? "bg-success text-white"
                  : "text-dark"
              }`}
              style={{
                borderRadius: "6px",
                border: "1px solid #D0D5DD",
                transition: "all 300ms ease-in",
              }}
              onMouseEnter={() => setNextButtonHovered(true)}
              onMouseLeave={() => setNextButtonHovered(false)}
            >
              <span>Next</span>
              <CgArrowLongRight />
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Blog;
