import { useEffect, useContext } from "react";
import { Form, Formik } from "formik";
import { authValidationSchema } from "../utils/validation";
import { useMutation } from "react-query";
import { loginUser } from "../handlers/authHandler";
import { toast } from "../components/toast/toast";
import Button from "../components/button/button";
import { AuthContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Applogo from "../assets/logo.svg";

const Login = () => {
  const { handleAuthState } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);
  const navigate = useNavigate();
  let initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (cookies["sofresh-admin-token"] && cookies["sofresh-admin-user"]) {
      handleAuthState({
        token: cookies["sofresh-admin-token"],
        user: cookies["sofresh-admin-user"],
      });
      navigate("/dashboard");
    }
  }, [cookies, handleAuthState, navigate]);

  const { mutate: loginUserMutation, isLoading } = useMutation(loginUser, {
    onSuccess: async res => {
      handleAuthState(res.data);
      navigate("/dashboard");
    },
    onError: e => {
      console.error({ e });
      toast.error("Incorrect login credentials");
    },
  });

  const handleSubmit = (values: any) => {
    loginUserMutation(values);
  };

  return (
    <main className="d-flex w-100">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-6 col-md-6 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center my-4">
                <img src={Applogo} alt="" className="img-fluid " />
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="m-sm-4">
                    <div className="text-center">
                      <h1 className="h2">Welcome, Admin</h1>
                      <p>Sign in to your account to continue</p>
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={authValidationSchema}
                      onSubmit={values => handleSubmit(values)}>
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="flex-column w-full align-items-center">
                          <div className="mb-4 w-full">
                            <label className="form-label">Email</label>
                            <input
                              className="form-control form-control-lg"
                              type="email"
                              name="email"
                              placeholder="Enter your email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                          </div>
                          <div className="mb-4 w-full">
                            <label className="form-label">Password</label>
                            <input
                              className="form-control form-control-lg"
                              type="password"
                              name="password"
                              placeholder="Enter your password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <small>
                              <button
                                className="btn btn-link pt-2"
                                type="button">
                                Forgot password?
                              </button>
                              {/* <a href="#">Forgot password?</a> */}
                            </small>
                          </div>
                          {/* <div>
														<div className="mb-3">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="radio"
																	required
																/>
																<label className="form-check-label">
																	Sign-in as an Admin
																</label>
															</div>
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="radio"
																	required
																/>
																<label className="form-check-label">
																	Sign-in as a Subadmin
																</label>
															</div>
														</div>
													</div> */}
                          <div className="text-center mt-4">
                            <Button type="submit" loading={isLoading}>
                              Sign in
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
