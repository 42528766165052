import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AppContext";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import {
  UserAccountData,
  getUserAccounts,
} from "../../../handlers/userAccounts";
import Table from "../../../components/table/Table";
import { Oval } from "react-loader-spinner";

const UserAccounts = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);

  const { data: userAccounts, isLoading: isUsersAccountsLoading } = useQuery(
    [`admin--users-accounts`],
    () => getUserAccounts(cookies["sofresh-admin-token"])
  );

  const columns = [
    {
      name: `Full Name`,
      selector: (userAccounts: UserAccountData) => userAccounts?.name,
      sortable: true,
    },
    {
      name: `Email`,
      selector: (userAccounts: UserAccountData) => userAccounts?.email,
      sortable: true,
    },
    {
      name: `Phone Number`,
      selector: (userAccounts: UserAccountData) => userAccounts?.phone_no,
      sortable: true,
    },
    {
      name: `Health Concerns`,
      selector: (userAccounts: UserAccountData) =>
        userAccounts?.meta?.allergies ? userAccounts?.meta?.allergies : "None",
      sortable: true,
    },
    {
      name: `Expectant Mum`,
      selector: (userAccounts: UserAccountData) =>
        userAccounts?.meta?.expectant_mum
          ? userAccounts?.meta?.expectant_mum
          : "No",
      sortable: true,
    },
    {
      name: `Status`,
      selector: (userAccounts: UserAccountData) =>
        userAccounts.is_active ? (
          <span className="badge bg-success outline-0 border-0">Yes</span>
        ) : (
          <span className="badge bg-danger outline-0 border-0">No</span>
        ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (userAccounts: UserAccountData) => (
        <div className="d-flex  align-items-center justify-content-center gap-3">
          <button
            type="button"
            onClick={() => navigate(`/user-accounts/${userAccounts.id}`)}
            className="bg-transparent outline-0 border-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title="View User Account">
            <i className="bi bi-eye-fill me-2"></i>
          </button>

          <button
            type="button"
            onClick={() => navigate(`/user-accounts/update/${userAccounts.id}`)}
            className="bg-transparent outline-0 border-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Update User Account">
            <i className="bi bi-pencil-square me-2"></i>
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <h1 className="w-100 h3 mb-4">
          <strong>Accounts</strong>
        </h1>

        {user?.role === "admin" && (
          <div className="row">
            <div className="col-12 d-flex">
              <div className="card flex-fill">
                {isUsersAccountsLoading ? (
                  <div className="my-8 w-full h-full flex align-items-center justify-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      {userAccounts ? (
                        <Table data={userAccounts} columns={columns} />
                      ) : (
                        <div className="my-8 w-full h-full flex align-items-center justify-center">
                          <Oval
                            height="40"
                            width="40"
                            color="#495057"
                            visible={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default UserAccounts;
