import React, { useState } from "react";
import { Form, Formik } from "formik";
import Button from "../../../components/button/button";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  getProductCategories,
  createProductHandler,
} from "../../../handlers/productHandler";
import { getStores } from "../../../handlers/storeHandler";
import { toast } from "../../../components/toast/toast";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../../../utils/s3Config";
import { Oval } from "react-loader-spinner";

const CreateProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [images, setImages] = useState<(string | undefined)[]>([]);
  const { data: productCategories } = useQuery("product-categories", () =>
    getProductCategories(cookies["sofresh-admin-token"])
  );
  const { data: stores } = useQuery("stores", () =>
    getStores(cookies["sofresh-admin-token"])
  );

  let initialValues = {
    name: "",
    price: "",
    discount: "",
    store_id: "",
    category_id: "",
    is_active: true,
    quantity: 10,
    description: "",
  };

  const handleFileUpload = async (file: File) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "products",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.error(exception);
    }
  };

  const handleFileInput = async (e: any) => {
    const files = e.target.files;
    setUploadLoading(true);
    const fileLocations = [];

    for (let i = 0; i < files.length; i++) {
      const loc = await handleFileUpload(files[i]);
      fileLocations.push(loc);
    }
    setUploadLoading(false);
    setImages(fileLocations);
  };

  const { mutate: createProductMutation, isLoading } = useMutation(
    createProductHandler,
    {
      onSuccess: async res => {
        console.log({ res });
        toast.success("Product created successfully");
        await queryClient.invalidateQueries(["products"]);
        navigate("/products");
      },
      onError: (e: string) => {
        console.log({ e });
        toast.error(e);
      },
    }
  );

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      store_id: values.store_id ? Number(values.store_id) : "",
      category_id: values.category_id ? Number(values.category_id) : "",
      image: images,
    };
    createProductMutation({ token: cookies["sofresh-admin-token"], data });
  };
  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <h1 className="h3 mb-3">Create Product</h1>
          </div>
        </div>
        <div className="row">
          <div className="">
            <div className="card">
              <div className="card-body">
                <div className="m-sm-4">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={values => handleSubmit(values)}>
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label">Product Name</label>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="name"
                            placeholder="Enter product name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Product Description
                          </label>
                          <textarea
                            className="form-control form-control-lg"
                            name="description"
                            placeholder="Enter product description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={100}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Upload Product Image
                          </label>
                          <div className="flex align-items-center ">
                            <input
                              className="form-control form-control-sm"
                              id="formFileSm"
                              type="file"
                              accept="image/*"
                              onChange={handleFileInput}
                              required={true}
                              multiple
                            />
                            {uploadLoading && (
                              <Oval
                                wrapperClass="ml-2"
                                height={20}
                                width={20}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Price</label>
                          <input
                            className="form-control form-control-lg"
                            type="number"
                            name="price"
                            placeholder="Enter price"
                            value={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Promo Price</label>
                          <input
                            className="form-control form-control-lg"
                            type="number"
                            name="discount"
                            placeholder="Enter promo price"
                            value={values.discount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Product Category</label>
                          <select
                            className="form-select form-control form-control-lg"
                            name="category_id"
                            value={values.category_id}
                            onChange={handleChange}>
                            <option>Select a Category</option>
                            {productCategories?.map(
                              (item: any, index: number) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Store</label>
                          <select
                            className="form-select form-control form-control-lg"
                            onChange={handleChange}
                            name="store_id"
                            value={values.store_id}>
                            <option>Select a Store</option>
                            {stores?.data.map((item: any, index: number) => (
                              <option key={index} value={item.id}>
                                {item.store_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="text-center mt-3 flex justify-center">
                          <Button
                            type="submit"
                            loading={isLoading}
                            disabled={uploadLoading}>
                            Add Product
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateProduct;
