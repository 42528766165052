import Button from "../../../components/button/button";
import { toast } from "../../../components/toast/toast";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  getSingleNavigationButton,
  updateNavigationButton,
} from "../../../handlers/navigationHandler";
import { Form, Formik } from "formik";
import { createButtonValidationSchema } from "../../../utils/validation";

const UpdateNavigationButton = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id: navigationId } = useParams();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const { data: navigationData, isLoading: navigationDataLoading } = useQuery(
    `${navigationId}-subadmin`,
    () =>
      getSingleNavigationButton({
        token: cookies["sofresh-admin-token"],
        id: navigationId,
      })
  );

  const { mutate: updateNavigation, isLoading } = useMutation(
    updateNavigationButton,
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([
          "sub-admins",
          `${navigationId}-subadmin`,
          navigate("/navigation-buttons"),
          toast.success("Button updated successfully"),
        ]);
      },
      onError: (e: string) => {
        console.log({ e });
        navigate("/navigation-buttons");
        toast.error(e);
      },
    }
  );

  let initialValues = {
    url: navigationData?.url || "",
    title: navigationData?.title || "",
    status: "true",
  };

  const handleSubmit = (values: any) => {
    const data = {
      url: values.url,
      title: values.title,
      is_active: values.status === "true" ? true : false,
    };
    updateNavigation({
      data: data,
      token: cookies["sofresh-admin-token"],
      id: navigationId,
    });
  };

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex items-center justify-content-between">
              <h1 className="h3">Update Button</h1>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card p-5">
              {navigationDataLoading ? (
                <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                  <Oval height="40" width="40" color="#495057" visible={true} />
                </div>
              ) : (
                <div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={createButtonValidationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="row mb-4">
                          <div className="col">
                            <label className="form-label">Button Name</label>
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                              required={true}
                            />
                          </div>
                          <div className="col">
                            <label className="form-label">Url Link</label>
                            <input
                              className="form-control form-control-lg"
                              type="url"
                              name="url"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.url}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-6">
                            <label className="form-label">Status</label>
                            <select
                              className="form-select form-control form-control-lg"
                              name="status"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                              required={true}
                            >
                              <option>Select Status</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>

                        <div className="text-center mt-3 flex justify-center align-items-center">
                          <Button type="submit" loading={isLoading}>
                            Save Change
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UpdateNavigationButton;
