import React from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import Button from "../../../components/button/button";
import { Formik } from "formik";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  updateProductCategory,
  getSingleProductCategory,
} from "../../../handlers/productHandler";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "../../../components/toast/toast";
import { useCookies } from "react-cookie";
import { Oval } from "react-loader-spinner";

const EditProductCategory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [cookies] = useCookies(["sofresh-admin-token"]);

  const { data: categoryData, isLoading: isCategoryLoading } = useQuery(
    `${slug}-product-category`,
    () =>
      getSingleProductCategory({ token: cookies["sofresh-admin-token"], slug })
  );

  let initialValues = {
    name: categoryData?.name || "",
    description: categoryData?.description || "",
  };

  const { mutate: updateProductCategoryMutation, isLoading } = useMutation(
    updateProductCategory,
    {
      onSuccess: async res => {
        console.log({ res });
        toast.success("Product category updated successfully");
        await queryClient.invalidateQueries(["product-categories"]);
        navigate("/product/categories");
      },
      onError: (e: string) => {
        console.log({ e });
        toast.error(e);
      },
    }
  );

  const handleSubmit = (values: any) => {
    updateProductCategoryMutation({
      token: cookies["sofresh-admin-token"],
      data: values,
      slug,
    });
  };

  return (
    <DashboardLayout>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12">
              <h1 className="h3 mb-3">Update Category</h1>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="card">
                <div className="card-body">
                  {isCategoryLoading ? (
                    <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                      <Oval
                        height="40"
                        width="40"
                        color="#495057"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="m-sm-4">
                      <Formik
                        initialValues={initialValues}
                        onSubmit={values => handleSubmit(values)}>
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label">
                                Category Name
                              </label>
                              <input
                                className="form-control form-control-lg"
                                type="text"
                                name="name"
                                placeholder="Enter product name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Category Description
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                name="description"
                                placeholder="Enter description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                            </div>
                            <div className="text-center mt-3 flex justify-center">
                              <Button
                                type="submit"
                                loading={isLoading}
                                disabled={isLoading}>
                                Update Category
                              </Button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default EditProductCategory;
