// REACT & DEFAULTS
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AppContext";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";

// COMPONENTS, UTILs & LIBs
import {
  UserAccountData,
  getSingleUserAccount,
} from "../../../../handlers/userAccounts";
import Table from "../../../../components/table/Table";
import { Oval } from "react-loader-spinner";

const SingleUserAccount = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userID, setUserID] = useState<string>("");
  const { user } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);

  // Effect to set userID when id changes
  useEffect(() => {
    if (id) {
      setUserID(id);
    }
  }, [id]);

  // Query for user account data
  const { data: userAccount, isLoading: isUserAccountDataLoading } =
    useQuery<UserAccountData>([`user-account-${id}`], () =>
      getSingleUserAccount({
        token: cookies["sofresh-admin-token"],
        id: id ?? userID,
      })
    );

  const columns = [
    {
      name: `Full Name`,
      selector: (userAccount: UserAccountData) => userAccount?.name,
      sortable: true,
    },
    {
      name: `Email`,
      selector: (userAccount: UserAccountData) => userAccount?.email,
      sortable: true,
    },
    {
      name: `Phone Number`,
      selector: (userAccount: UserAccountData) => userAccount?.phone_no,
      sortable: true,
    },
    {
      name: `Health Concerns`,
      selector: (userAccount: UserAccountData) =>
        userAccount?.meta?.allergies ? userAccount?.meta?.allergies : "None",
      sortable: true,
    },
    {
      name: `Expectant Mum`,
      selector: (userAccount: UserAccountData) =>
        userAccount?.meta?.expectant_mum
          ? userAccount?.meta?.expectant_mum
          : "No",
      sortable: true,
    },
    {
      name: `Status`,
      selector: (userAccount: UserAccountData) =>
        userAccount.is_active ? (
          <span className="badge bg-success outline-0 border-0">Yes</span>
        ) : (
          <span className="badge bg-danger outline-0 border-0">No</span>
        ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (userAccount: UserAccountData) => (
        <div className="d-flex  align-items-center justify-content-center gap-3">
          <button
            type="button"
            onClick={() => navigate(`/user-accounts/update/${userAccount.id}`)}
            className="bg-transparent outline-0 border-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Update User Account">
            <i className="bi bi-pencil-square me-2"></i>
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <h1 className="w-100 h3 mb-4">
          <strong>User Account Details</strong>
        </h1>

        {user?.role === "admin" && (
          <div className="row">
            <div className="col-12 d-flex">
              <div className="card flex-fill">
                {isUserAccountDataLoading ? (
                  <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      {userAccount ? (
                        <Table data={[userAccount]} columns={columns} />
                      ) : (
                        <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                          <p>No User Data Avalable</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default SingleUserAccount;
