import { axiosClient } from "../utils/axiosClient";

/*=============================================
=               PRODUCT TYPES                 =
=============================================*/

export type CategoryResponseData = {
  description: string;
  id: string | number;
  name: string;
  slug: string;
  image: string;
  is_active: boolean;
  minimum_price: string | number;
};

export type ProductResponseData = {
  categories: Array<CategoryResponseData>;
  description: string;
  discount: string;
  id: string | number;
  image: Array<string>;
  is_active: boolean;
  name: string;
  price: string | number;
  review_count: number;
  slug: string;
};
/*----------      PRODUCT TYPES       ----------*/

/*=============================================
=               CREATE HANDLERS               =
=============================================*/

/*----------    PRODUCT HANDLER     ----------*/
export const createProductHandler = async ({ data, token }: any) => {
  try {
    const response = await axiosClient.post(`/products`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error: any) {
    console.error(error.message);
    if (
      error?.response?.data?.error?.name &&
      typeof error.response.data.error.name === "string"
    ) {
      throw error.response.data.error.name;
    }
    throw new Error("An error occurred. Please try again.");
  }
};

export const createProductCategory = async ({ data, token }: any) => {
  try {
    const response = await axiosClient.post(`/categories`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error: any) {
    console.error({ error });
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0];
    }
    if (error?.response?.data?.error?.description) {
      throw error?.response?.data?.error.description[0];
    }
    throw new Error("An error occured. Please try again.");
  }
};

/*----------    MEAL PLAN HANDLER     ----------*/
export const createPlanType = async ({ data, token }: any) => {
  try {
    const response = await axiosClient.post(`/admin/plan-types`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0];
    }
    throw new Error("An error occured. Please try again.");
  }
};

export const createPlan = async ({ data, token }: any) => {
  try {
    const response = await axiosClient.post(`/admin/plans`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
/*=====      END OF CREATE HANDLERS     ======*/

/**
 *
 *
 *
 */

/*=============================================
=                READ HANDLERS                =
==============================================*/

/*----------  READ PRODUCT HANDLER  ----------*/
export const getproducts = async (token: string) => {
  try {
    const response = await axiosClient.get(`/products?page_size=5000`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

/*----------  READ PRODUCT In AN ADMIN's STORE  ----------*/
export const getProductsInAnAdminsStore = async (token: string) => {
  try {
    const response = await axiosClient.get(
      `/subadmin/products?page_size=5000`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getSingleProduct = async (
  token: string,
  slug: string
): Promise<{ data: Product }> => {
  try {
    const { data } = await axiosClient.get<{ data: Product }>(
      `/products/${slug}`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occurred. Please try again.");
  }
};

/*----------  READ PRODUCT CATEGORY HANDLER  ----------*/
export const getProductCategories = async (token: string) => {
  try {
    const response = await axiosClient.get(`/categories?page_size=5000`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getSingleProductCategory = async ({ token, slug }: any) => {
  try {
    const response = await axiosClient.get(`/categories/${slug}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

/*----------    READ MEAL HANDLER    ----------*/

export const getPlans = async (token: string) => {
  try {
    const response = await axiosClient.get(`/plans`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getSinglePlan = async ({ token, slug }: any) => {
  try {
    const response = await axiosClient.get(`/plans?slug=${slug}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

/*----------  READ MEAL PLANS HANDLER  ----------*/
export const getPlanTypes = async (token: string) => {
  try {
    const response = await axiosClient.get(`/plans/plan-type`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

/*----------  READ SINGLE MEAL PLANS HANDLER  ----------*/
export const getSingleMealPlan = async ({ token, slug }: any) => {
  try {
    const response = await axiosClient.get(`/plans/plan-type?slug=${slug}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
/*=====       END OF READ HANDLERS        ======*/

/**
 *
 *
 *
 */

/*=============================================
=               UPDATE HANDLERS               =
==============================================*/

/*----------  UPDATE  PRODUCT HANDLER    ----------*/
export const updateProductHandler = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.put(`/products/${slug}`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error: any) {
    console.error({ error });
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0];
    }
    throw new Error("An error occured. Please try again.");
  }
};

/*----------  UPDATE  PRODUCT CATEGORY HANDLER    ----------*/
// export const updateProductCategory = async ({ data, token, slug }: any) => {
//   try {
//     const response = await axiosClient.put(`/categories/${slug}`, data, {
//       headers: {
//         'Content-Type': 'application/json',
//         authorization: `Bearer ${token}`,
//       },
//     })

//     return response
//   } catch (error: any) {
//     console.error({ error })
//     if (error?.response?.data?.error?.name) {
//       throw error?.response?.data?.error.name[0]
//     }
//     if (error?.response?.data?.error?.description) {
//       throw error?.response?.data?.error.description[0]
//     }
//     throw new Error('An error occured. Please try again.')
//   }
// }

export const updateProductCategory = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.put(`/categories/${slug}`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

// export const updateProductCategory = async ({ data, token, slug }: any) => {
//   try {
//     const response = await axiosClient.put(`/admin/subadmins/${slug}`, data, {
//       headers: {
//         'Content-Type': 'application/json',
//         authorization: `Bearer ${token}`,
//       },
//     })
//     return response
//   } catch (error: any) {
//     console.error({ error })
//     if (error?.response?.data?.error?.name) {
//       throw error?.response?.data?.error.name[0]
//     }
//     if (error?.response?.data?.error?.phone_no) {
//       throw error?.response?.data?.error.phone_no[0]
//     }
//     throw new Error('An error occured. Please try again.')
//   }
// }

/*----------  UPDATE MEAL PLAN HANDLERS    ----------*/
export const updateMealPlan = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.post(`/admin/plan-types/${slug}`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0];
    }
    throw new Error("An error occured. Please try again.");
  }
};

/*----------  UPDATE PLAN HANDLER    ----------*/
export const updatePlan = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.post(`/admin/plans/${slug}`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

/*----------   ADD A PRODUCT TO AN ADMIN's STORE   ----------*/
export const addProductToAnAdminsStore = async ({
  token,
  slug,
  is_active,
}: any) => {
  try {
    const response = await axiosClient.put(
      `/subadmin/products/${slug}`,
      (is_active = { is_active }),
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

/*----------   ADD A PLAN TYPE TO AN ADMIN's STORE   ----------*/
export const addPlanTypeToAnAdminsStore = async ({
  token,
  slug,
  is_active,
}: any) => {
  try {
    const response = await axiosClient.put(
      `/subadmin/plan-types/${slug}`,
      (is_active = { is_active }),
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
/*=====      END OF UPDATE HANDLERS      ======*/

/**
 *
 *
 *
 */

/*=============================================
=                  DUMPS                      =
==============================================*/
// export const getSingleProduct = async (token: string, slug: string) => {
//   try {
//     const response = await axiosClient.get(`/products/${slug}`, {
//       headers: {
//         'Content-Type': 'application/json',
//         authorization: `Bearer ${token}`,
//       },
//     })
//     return response.data.data
//   } catch (error: any) {
//     console.error({ error })
//     throw new Error('An error occured. Please try again.')
//   }
// }

// export const createProductHandler = async ({ data, token }: any) => {
//   try {
//     const response = await axiosClient.post(`/products`, data, {
//       headers: {
//         'Content-Type': 'application/json',
//         authorization: `Bearer ${token}`,
//       },
//     })

//     return response
//   } catch (error: any) {
//     console.error(error.message)
//     // console.error({ error?.message })
//     if (error?.response?.data?.error?.name) {
//       throw error?.response?.data?.error.name[0]
//     }
//     // throw 'An error occured. Please try again.'
//   }
// }

// export const getSingleProductCategories = async ({ token, slug }: any) => {
//   try {
//     const response = await axiosClient.get(`/categories`, {
//       headers: {
//         'Content-Type': 'application/json',
//         authorization: `Bearer ${token}`,
//       },
//     })
//     return response.data.data
//   } catch (error: any) {
//     console.error({ error })
//     throw new Error('An error occured. Please try again.')
//   }
// }
/*=====           End of DUMPS          ======*/
