// REACT & DEFAULTS
import { ChangeEvent, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'

// COMPONENTS, LIBs & UTILs
import Modal from '../../../components/Modal'
import Button from '../../../components/button/button'
import { toast } from '../../../components/toast/toast'
import { addPlanTypeToAnAdminsStore } from '../../../handlers/productHandler'

// STYLES
import styles from './AddPlanTypeToAdminsStore.module.css'

interface AddPlanTypeToAdminsStoreModalProps {
  onClose: () => void
  planTypeName: string
  slug: string
  is_active: boolean
  isOpen: boolean
}

const AddPlanTypeToAdminsStoreModal: React.FC<
  AddPlanTypeToAdminsStoreModalProps
> = ({ isOpen, onClose, planTypeName, slug, is_active }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState<boolean>(is_active)
  const [cookies] = useCookies(['sofresh-admin-token', 'sofresh-admin-user'])

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value === 'true'
    setSelectedOption(value)

    // console.log('Selected value:', value)
    // console.log('Selected value Type:', typeof value)
  }

  // MUTATE FORM DATA BEFORE SUBMISSION
  const {
    mutate: addPlanTypeToAdminsStore,
    isLoading: isaddPlanTypeToAdminsStoreMutationLoading,
  } = useMutation(addPlanTypeToAnAdminsStore, {
    onSuccess: async res => {
      await queryClient.invalidateQueries(['plan-types'])
      onClose()
      // navigate('/planTypes/sub-admin')
      toast.success('Plan Type availability updated successfully')
    },
    onError: (e: string) => {
      console.error({ e })
      navigate('/planTypes')
      toast.error(e)
    },
  })

  // HANDLE FORM SUBMISSION
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // console.log('Submission Initiated')
    // console.log('slug', slug)
    // console.log('selectedOption', selectedOption)

    addPlanTypeToAdminsStore({
      token: cookies['sofresh-admin-token'],
      slug: slug,
      is_active: selectedOption,
      role: cookies['sofresh-admin-user']?.role,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`PlanType Availability`}
      subTitle={`Update the planType's status by selecting the dropdown below`}>
      <form onSubmit={handleFormSubmit}>
        <div className='pt-3 py-5'>
          <div className='form-group d-flex flex-column gap-2 py-2'>
            <label
              className={styles.addPlanTypeToStoreModalFormTitle}
              htmlFor='planTypeIsActive'>
              Plan Type Name
            </label>
            <input
              className={styles.addPlanTypeToStoreModalFormSelect}
              type='text'
              value={planTypeName}
              disabled
            />
          </div>
          <div className='form-group d-flex flex-column gap-2 py-2 mb-3'>
            <label
              className={styles.addPlanTypeToStoreModalFormTitle}
              htmlFor='planTypeIsActive'>
              Change Plan Type availability in your Store
            </label>
            <select
              name='planTypeIsActive'
              className={styles.addPlanTypeToStoreModalFormSelect}
              value={selectedOption.toString()}
              onChange={handleSelectChange}>
              <option value='true'>Available</option>
              <option value='false'>Not Available</option>
            </select>
          </div>
        </div>

        <Button
          type='submit'
          formControl
          title={
            isaddPlanTypeToAdminsStoreMutationLoading
              ? 'Saving...'
              : 'Save Changes'
          }
        />
      </form>
    </Modal>
  )
}

export default AddPlanTypeToAdminsStoreModal
