import * as Yup from "yup";

export const authValidationSchema = Yup.object({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

export const createSubAdminsValidationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  phone: Yup.string().required(),
  status: Yup.string().required(),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters"),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const createButtonValidationSchema = Yup.object({
  title: Yup.string()
    .max(10, "Button Name must be at most 10 characters")
    .required("Button Name is required"),
  url: Yup.string().required("Url Link is required"),
  status: Yup.string().required("Status is required"),
});

export const createStoreValidationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  phone_no: Yup.string().required(),
  longitude: Yup.string().required(),
  latitude: Yup.string().required(),
  sub_admin: Yup.string().required(),
});

export const createPlanValidationSchema = Yup.object({
  name: Yup.string().required("Name is required!"),
  email: Yup.string().required("Email is required!"),
  description: Yup.string(),
});

export const createPlanTypeValidationSchema = Yup.object({
  name: Yup.string().required("Name is required!"),
  description: Yup.string().required("Description is required!"),
  plan_id: Yup.string().required("Plan is required!"),
});

export const addLocationValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name of location is required!"),
  phone_no: Yup.string().required("Phone number is required!"),
  address: Yup.string().required("Location address is required!"),
  delivery_option: Yup.string().required("Please select a delivery option!"),
});
