import { ToastContainer, toast as tast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ucfirst } from '../../utils/helper'

const toastConfig = {
  hideProgressBar: true,
  duration: 7000,
  pauseOnHover: false,
}

interface MessageProps {
  message: string
}

export const Success = ({ message }: MessageProps): JSX.Element => (
  <div>
    <span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
  </div>
)

export const Error = ({ message }: MessageProps): JSX.Element => (
  <div>
    <span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
  </div>
)

export const Info = ({ message }: MessageProps): JSX.Element => (
  <div>
    <span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
  </div>
)

// { error, success, message, clearMessage }
const Toast = () => {
  //   const dispatch = useDispatch();
  //   const error = useSelector(getError);
  //   const success = useSelector(getSuccess);
  //   const message = useSelector(getMessage);
  //   useEffect(() => {
  //     openNotification();
  //   }, [success, error]);

  //   const openNotification = () => {
  //     if (success && message) {
  //       tast.success(<Success message={message} />, {
  //         hideProgressBar: true,
  //       });
  //     }
  //     if (error && message) {
  //       tast.error(<Error message={message} />, {
  //         hideProgressBar: true,
  //       });
  //     }
  //     setTimeout(function () {
  //       dispatch(clearMessage());
  //     }, 3000);
  //   };

  return (
    <ToastContainer
      autoClose={7000}
      pauseOnHover={true}
      position='top-center'
      transition={Slide}
    />
  )
}

interface toastConfigProps {
  [key: string]: unknown
}
interface toastProps {
  success: (message: string, otherConfig?: toastConfigProps) => void
  error: (message: string, otherConfig?: toastConfigProps) => void
  info: (message: string, otherConfig?: toastConfigProps) => void
}

export const toast: toastProps = {
  success: (message: string, otherConfig?: toastConfigProps) => {
    tast.success(<Success message={message} />, {
      ...toastConfig,
      ...otherConfig,
    })
  },
  info: (message: string, otherConfig?: toastConfigProps) => {
    tast.info(<Info message={message} />, {
      ...toastConfig,
      ...otherConfig,
    })
  },
  error: (message: string, otherConfig?: toastConfigProps) => {
    tast.error(<Error message={message} />, {
      ...toastConfig,
      ...otherConfig,
    })
  },
}

export default Toast
