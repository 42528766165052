import { FormEvent, ChangeEvent, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useMutation, useQuery, useQueryClient } from "react-query";

// COMPONENTS, LIBs & UTILs
import Modal from "../../../components/Modal";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../../../utils/s3Config";
import { getSinglePlan, updatePlan } from "../../../handlers/productHandler";

// IMAGES & ICONS
import { Oval } from "react-loader-spinner";
import upload from "../../../assets/uploadPNG.png";
import { toast } from "../../../components/toast/toast";

// STYLES
import styles from "./Plans.module.css";

interface IUpdatePlanModalProps {
  onClose: () => void;
  isOpen: boolean;
  slug: string;
}

interface IPlanData {
  image: (string | undefined)[];
  name: string;
  email: string;
  description: string;
  is_active?: boolean;
}

const UpdatePlanModal = ({ isOpen, onClose, slug }: IUpdatePlanModalProps) => {
  const queryClient = useQueryClient();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const navigate = useNavigate();

  const selectedImgRef = useRef<HTMLImageElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [images, setImages] = useState<(string | undefined)[]>([]);

  const { data: planData, isLoading: isPlanDataLoading } = useQuery(
    `${slug}-plan`,
    () => getSinglePlan({ token: cookies["sofresh-admin-token"], slug })
  );
  // console.log('planData: ', planData)

  // INITIAL PLANS DATA
  const [plan, setPlan] = useState<IPlanData>({
    image: images,
    name: "",
    email: "",
    description: "",
    is_active: true,
  });
  // console.log('PlanCAT: ', plan_category)

  useEffect(() => {
    if (planData) {
      setPlan({
        image: planData ? planData[0]?.name : images,
        name: planData && planData[0]?.name,
        email: planData && planData[0]?.email,
        description: planData && planData[0]?.description,
        is_active: planData && planData[0]?.is_active,
      });
    }
  }, [images, planData]);

  // HANDLE PLAN IMAGE UPLOAD
  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "plans",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.error(exception);
    }
  };

  // DELETE SELECTED IMAGE
  const handleDeleteImage = () => {
    selectedImgRef?.current?.setAttribute("src", upload);
  };

  const handlePlanImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setUploadLoading(true);

    try {
      const imageUrl = await handleFileUpload(file);
      setImages([imageUrl]);
      setPlan((prevPlan) => ({
        ...prevPlan,
        image: [imageUrl],
      }));
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    } finally {
      setUploadLoading(false);
    }
  };
  // END OF HANDLE PLAN IMAGE UPLOAD

  // FORM CONTROL FOR TEXT INPUTS
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setPlan((prevPlan) => ({ ...prevPlan, [name]: value }));
  };

  // FORM CONTROL FOR SELECT OPTIONS INPUTS(is_active: string <true|false>)
  const handleSetActive = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "true") {
      setPlan((prevPlan) => ({ ...prevPlan, is_active: true }));
    } else {
      setPlan((prevPlan) => ({ ...prevPlan, is_active: false }));
    }
  };

  // HANDLE PLANS MUTATION
  const { mutate: updatePlanMutation, isLoading } = useMutation(updatePlan, {
    onSuccess: async (res) => {
      // console.log({ res })
      toast.success("Plan updated successfully");
      await queryClient.invalidateQueries(["plans"]);
      navigate("/plans");
    },
    onError: (e: string) => {
      console.error({ e });
      toast.error(e);
    },
  });

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.table('PLAN: ', plan)

    const data = {
      ...plan,
      image: images.length > 0 ? images : planData && planData[0]?.image,
    };
    updatePlanMutation({
      token: cookies["sofresh-admin-token"],
      data,
      slug,
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Update Plan`}
      subTitle={`Upload image and enter information below to add new plan`}
    >
      <form onSubmit={handleSubmit}>
        {isPlanDataLoading ? (
          <div className="my-8 w-full h-full d-flex  align-items-center justify-content-center">
            <Oval height="40" width="40" />
          </div>
        ) : (
          <>
            <div className={styles.modalBody}>
              <div className={`form-group py-2 ${styles.modalImageContainer}`}>
                <div>
                  {uploadLoading ? (
                    <div className="my-8 w-100 h-100 d-flex  align-items-center justify-content-center">
                      <Oval height="40" width="40" />
                    </div>
                  ) : (
                    <img
                      ref={selectedImgRef}
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : planData[0]
                          ? planData[0]?.image[0]
                          : upload
                      }
                      alt="preview"
                      onClick={handleUploadBtnClick}
                      className={
                        planData[0]?.image[0] || selectedFile
                          ? styles.imageContainer
                          : styles.uploadIcon
                      }
                    />
                  )}
                  <input
                    type="file"
                    id="uploadPlanImage"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handlePlanImageUpload}
                  />
                </div>

                <div className="w-100 pt-2">
                  <p className={`w-100 ${styles.modalImageText}`}>
                    <label htmlFor="uploadPlanImage">
                      <span className="modal-imageSpan">Click to upload </span>
                    </label>
                    <span> a PNG, JPEG or WEBP image </span>
                    <span
                      className={`w-100 ${styles.modalImageText} d-md-block`}
                    >
                      (image size should not exceed 400x400)
                    </span>
                  </p>
                </div>

                {(planData[0]?.image[0] || selectedFile) && (
                  <div className={styles.imageContolBtns}>
                    <button
                      type="button"
                      onClick={handleDeleteImage}
                      className={`btn btn-sm ${styles.deleteImageBtn}`}
                    >
                      Delete Image
                    </button>
                    <label
                      htmlFor="uploadPlanImage"
                      className={`btn btn-sm ${styles.chooseNewImageBtn}`}
                    >
                      Pick New Image
                    </label>
                  </div>
                )}
              </div>

              <div className="form-group py-2">
                <label className={styles.formLabel} htmlFor="name">
                  Plan Name
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.formField}`}
                  id="name"
                  name="name"
                  value={plan.name}
                  onChange={handleInputChange}
                  placeholder="Enter name of plan"
                  required
                />
              </div>

              <div className="form-group py-2">
                <label className={styles.formLabel} htmlFor="description">
                  Email
                </label>
                <input
                  type="email"
                  className={`form-control ${styles.formField}`}
                  id="email"
                  name="email"
                  value={plan.email}
                  onChange={handleInputChange}
                  placeholder="Enter name of plan"
                  required
                />
              </div>

              <div className="form-group py-2">
                <label className={styles.formLabel} htmlFor="status">
                  Status:
                </label>
                <select
                  className={`form-control ${styles.formField}`}
                  id="status"
                  name="status"
                  value={plan.is_active ? "true" : "false"}
                  onChange={handleSetActive}
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>

              <div className="form-group py-2">
                <label className={styles.formLabel} htmlFor="description">
                  Description
                </label>
                <textarea
                  className={`form-control ${styles.formField}`}
                  id="description"
                  name="description"
                  value={plan.description}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                  required
                />
              </div>
            </div>

            <div
              className={`pt-4 ${styles.modalFooter} ${styles.btnContainer}`}
            >
              <button
                type="submit"
                className={`btn form-control ${styles.btnColor}`}
              >
                {isLoading ? (
                  <Oval
                    color="white"
                    wrapperClass="ml-2"
                    height={10}
                    width={10}
                  />
                ) : (
                  "Update Plan"
                )}
              </button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export default UpdatePlanModal;
