import { axiosClient } from '../utils/axiosClient'

/*=============================================
=               CREATE HANDLERS               =
=============================================*/

/*----------    CREATE A DELIVERY POINT     ----------*/
export const createDelieryPoints = async ({
  data,
  token,
}: {
  data: ICreateDeliveryPoint
  token: string
}) => {
  try {
    const response = await axiosClient.post(`/admin/delivery-points`, data, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })

    return response
  } catch (error: any) {
    console.error(error.message)
    if (
      error?.response?.data?.error?.name &&
      typeof error.response.data.error.name === 'string'
    ) {
      throw error.response.data.error.name
    }
    throw new Error('An error occurred. Please try again.')
  }
}

export const createFreeDelivery = async ({
  data,
  token,
}: {
  data: ICreateFreeDeliveriesData
  token: string
}) => {
  try {
    const response = await axiosClient.post(
      `admin/settings/free-deliveries`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )

    return response
  } catch (error: any) {
    console.error(error.message)
    if (
      error?.response?.data?.error?.name &&
      typeof error.response.data.error.name === 'string'
    ) {
      throw error.response.data.error.name
    }
    throw new Error('An error occurred. Please try again.')
  }
}
/*=====      END OF CREATE HANDLERS     ======*/

/**
 *
 *
 *
 */

/*=============================================
=                READ HANDLERS                =
==============================================*/

/*----------  READ PRODUCT HANDLER  ----------*/

/*----------  GET ALL DELIVERY PONTs DATA  ----------*/
export const getALlDeliveryPoints = async (token: string) => {
  try {
    const { data } = await axiosClient.get<{ data: IGetDeliveryPointsData[] }>(
      `/admin/delivery-points?page_size=273`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}

export const getDeliveryPoints = async (token: string) => {
  try {
    const { data } = await axiosClient.get<{ data: IGetDeliveryPointsData[] }>(
      `/admin/delivery-points`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}

/*----------  GET A SINGLE DELIVERY PONT DATA  ----------*/
export const getSingleDeliveryPoints = async ({
  slug,
  token,
}: {
  slug: string
  token: string
}): Promise<ISingleDeliveryPonitData[]> => {
  try {
    const { data } = await axiosClient.get<{
      data: ISingleDeliveryPonitData[]
    }>(`/admin/delivery-points?slug=${slug}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return data.data
  } catch (error) {
    console.error({ error })
    return []
  }
}

export const getFreeDeliveries = async (token: string) => {
  try {
    const { data } = await axiosClient.get<{ data: IGetFreeDeliveriesData[] }>(
      `/admin/settings/free-deliveries`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return data?.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}
/*=====       END OF READ HANDLERS        ======*/

/**
 *
 *
 *
 */

/*=============================================
=               UPDATE HANDLERS               =
==============================================*/

/*----------       UPDATE A SINGLE DELIVERY POINT       ----------*/
export const updateDeliveryPoint = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.put(
      `/admin/delivery-points/${slug}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )

    return response
  } catch (error: any) {
    console.log({ error })
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0]
    }
    throw new Error('An error occured. Please try again.')
  }
}
/*=====      END OF UPDATE HANDLERS      ======*/

/**
 *
 *
 *
 */

/*=============================================
=                  DUMPS                      =
==============================================*/

/*=====           End of DUMPS          ======*/
