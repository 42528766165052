import React, { useState } from "react";
import { Link } from "react-router-dom";
import newimage from "../../../assets/new-img.svg";

const WebsiteContent = () => {
  const [selectedButton, setSelectedButton] = useState("homepage");

  const handleButtonClick = (button: React.SetStateAction<string>) => {
    setSelectedButton(button);
  };

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex items-center justify-content-between">
              <h1 className="h3">Website Content</h1>
              <Link to={""} className="btn btn-lg btn-warning">
                Save Changes
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="toggle-bg">
              <div className="d-flex flex-row items-center gap-3">
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "homepage" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("homepage")}
                >
                  Homepage
                </button>
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "soFreshStory" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("soFreshStory")}
                >
                  The So Fresh Story
                </button>
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "returnPolicy" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("returnPolicy")}
                >
                  Return Policy
                </button>
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "privacyPolicy" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("privacyPolicy")}
                >
                  Privacy Policy
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mx-0">
          <div className="tab-content">
            {selectedButton === "homepage" && (
              <>
                <div className="mb-5">
                  <h3>Hero Section</h3>
                  <div className="row mt-4">
                    <div className="col-sm-6 image-container">
                      <img
                        src={newimage}
                        alt="hero-img"
                        className="img-fluid w-full"
                      />
                      <div className="centered-button">
                        <button className="text-white">Choose New Image</button>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <h4>Hero Text</h4>
                      <div className="mt-3">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="More Than Food.It's A Lifestyle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3>First Section</h3>
                  <div className="row mt-4">
                    <div className="col-sm-6 image-container">
                      <img
                        src={newimage}
                        alt="hero-img"
                        className="img-fluid w-full"
                      />
                      <div className="centered-button">
                        <button className="text-white">Choose New Image</button>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <h4>Main Text</h4>
                        <div className="mt-3">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="More Than Food.It's A Lifestyle"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <h4>Sub Text</h4>
                        <div className="mt-3">
                          <textarea
                            className="form-control form-control-lg"
                            rows={3}
                            placeholder="Lorem Ipsum Lorem Ipsum Lorem"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h3>Second Section</h3>
                  <div className="row mt-4">
                    <div className="col-sm-6 image-container">
                      <img
                        src={newimage}
                        alt="hero-img"
                        className="img-fluid w-full"
                      />
                      <div className="centered-button">
                        <button className="text-white">Choose New Image</button>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <h4>Main Text</h4>
                        <div className="mt-3">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="More Than Food.It's A Lifestyle"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <h4>Sub Text</h4>
                        <div className="mt-3">
                          <textarea
                            className="form-control form-control-lg"
                            rows={3}
                            placeholder="Lorem Ipsum Lorem Ipsum Lorem"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedButton === "soFreshStory" && (
              <p>Content for The So Fresh Story</p>
            )}
            {selectedButton === "returnPolicy" && (
              <p>Content for Return Policy</p>
            )}
            {selectedButton === "privacyPolicy" && (
              <p>Content for Privacy Policy</p>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default WebsiteContent;
