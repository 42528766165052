import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useMutation, useQueryClient } from 'react-query'

// COMPONENTS, UTILS & LIBs
import Modal from '../../../../components/Modal'
import Button from '../../../../components/button/button'
import { addProductToAnAdminsStore } from '../../../../handlers/productHandler'
import { toast } from '../../../../components/toast/toast'

// STYLES
import styles from './AddProductToAdminsStore.module.css'

interface AddProductToAdminsStoreModalProps {
  onClose: () => void
  productName: string
  slug: string
  is_active: boolean
  isOpen: boolean
}

const AddProductToAdminsStoreModal: React.FC<
  AddProductToAdminsStoreModalProps
> = ({ isOpen, onClose, productName, slug, is_active }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState<boolean>(is_active)
  const [cookies] = useCookies(['sofresh-admin-token', 'sofresh-admin-user'])

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value === 'true'
    setSelectedOption(value)

    // console.log('Selected value:', value)
    // console.log('Selected value Type:', typeof value)
  }

  // MUTATE FORM DATA BEFORE SUBMISSION
  const {
    mutate: addProductToAdminsStore,
    isLoading: isaddProductToAdminsStoreMutationLoading,
  } = useMutation(addProductToAnAdminsStore, {
    onSuccess: async res => {
      await queryClient.invalidateQueries(['products'])
      onClose()
      // navigate('/products/sub-admin')
      toast.success('Product availability updated successfully')
    },
    onError: (e: string) => {
      console.error({ e })
      navigate('/products')
      toast.error(e)
    },
  })

  // HANDLE FORM SUBMISSION
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // console.log('Submission Initiated')
    // console.log('slug', slug)
    // console.log('selectedOption', selectedOption)

    addProductToAdminsStore({
      token: cookies['sofresh-admin-token'],
      slug: slug,
      is_active: selectedOption,
      role: cookies['sofresh-admin-user']?.role,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Product Availability`}
      subTitle={`Update the product's status by selecting the dropdown below`}>
      <form onSubmit={handleFormSubmit}>
        <div className='pt-3 py-5'>
          <div className='form-group d-flex flex-column gap-2 py-2'>
            <label
              className={styles.addProductToStoreModalFormTitle}
              htmlFor='productIsActive'>
              Product Name
            </label>
            <input
              className={styles.addProductToStoreModalFormSelect}
              type='text'
              value={productName}
              disabled
            />
          </div>
          <div className='form-group d-flex flex-column gap-2 py-2 mb-3'>
            <label
              className={styles.addProductToStoreModalFormTitle}
              htmlFor='productIsActive'>
              Change product availability in your Store
            </label>
            <select
              name='productIsActive'
              className={styles.addProductToStoreModalFormSelect}
              value={selectedOption.toString()}
              onChange={handleSelectChange}>
              <option value='true'>Available</option>
              <option value='false'>Not Available</option>
            </select>
          </div>
        </div>

        <Button
          type='submit'
          formControl
          title={
            isaddProductToAdminsStoreMutationLoading
              ? 'Saving...'
              : 'Save Changes'
          }
        />
      </form>
    </Modal>
  )
}

export default AddProductToAdminsStoreModal
