import { axiosClient } from "../utils/axiosClient";

export const addLocation = async ({ data, token }: any) => {
  try {
    const response = await axiosClient.post(`/admin/outlets`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const updateLocation = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.put(`/admin/outlets/${slug}`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const deleteLocation = async ({ token, slug }: any) => {
  try {
    const response = await axiosClient.delete(`/admin/outlets/${slug}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getLocations = async <T>(token: string) => {
  try {
    const response = await axiosClient.get(`/admin/outlets`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const locations: T = response.data;
    return locations;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getLocation = async <T>({
  token,
  slug,
}: {
  token: string;
  slug: string;
}) => {
  try {
    const response = await axiosClient.get(`/admin/outlets/${slug}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const location: T = response.data;
    return location;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
