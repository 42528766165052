import React from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import Button from '../../../components/button/button'
import { Formik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { createProductCategory } from '../../../handlers/productHandler'
import { useNavigate } from 'react-router-dom'
import { toast } from '../../../components/toast/toast'
import { useCookies } from 'react-cookie'

const CreateProductCatgories = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [cookies] = useCookies(['sofresh-admin-token'])

  const { mutate: createProductCategoryMutation, isLoading } = useMutation(
    createProductCategory,
    {
      onSuccess: async res => {
        console.log({ res })
        toast.success('Product category created successfully')
        await queryClient.invalidateQueries(['product-categories'])
        navigate('/product/categories')
      },
      onError: (e: string) => {
        console.log({ e })
        navigate('/product/categories')
        toast.error(e)
      },
    }
  )

  const handleSubmit = (values: any) => {
    createProductCategoryMutation({
      token: cookies['sofresh-admin-token'],
      data: values,
    })
  }

  return (
    <DashboardLayout>
      <main className='content'>
        <div className='container-fluid p-0'>
          <div className='row'>
            <div className='col-md-12'>
              <h1 className='h3 mb-3'>Create Category</h1>
            </div>
          </div>
          <div className='row'>
            <div className=''>
              <div className='card'>
                <div className='card-body'>
                  <div className='m-sm-4'>
                    <Formik
                      initialValues={{
                        name: '',
                        description: '',
                      }}
                      onSubmit={values => handleSubmit(values)}>
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className='mb-3'>
                            <label className='form-label'>Category Name</label>
                            <input
                              className='form-control form-control-lg'
                              type='text'
                              name='name'
                              placeholder='Enter product name'
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={true}
                            />
                          </div>
                          <div className='mb-3'>
                            <label className='form-label'>
                              Category Description
                            </label>
                            <textarea
                              className='form-control'
                              rows={3}
                              name='description'
                              placeholder='Enter description'
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={true}
                            />
                          </div>

                          <div className='text-center mt-3 flex justify-center'>
                            <Button
                              type='submit'
                              loading={isLoading}
                              disabled={isLoading}>
                              Create Category
                            </Button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default CreateProductCatgories
