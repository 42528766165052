// REACT DEFAULTS & EXTERNAL LIBRARIES
import { useState } from "react";
// import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";

// COMPONENTS, LIBs & UTILs
import { getDeliveryPoints } from "../../../handlers/deliveryHandlers";
import { Oval } from "react-loader-spinner";
import Table from "../../../components/table/Table";
import CreateDeliveryPointModal from "./CreateDeliveryPointModal";
import UpdateDeliveryPointModal from "./UpdateDeliveryPointModal";

const DeliveryPoints = () => {
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [slug, setSlug] = useState<string>("");
  const [isCreateDeliveryPointModalOpen, setIsCreateDeliveryPointModalOpen] =
    useState(false);
  const [isUpdateDeliveryPointModalOpen, setIsUpdateDeliveryPointModalOpen] =
    useState(false);

  const { data: deliveryPoints, isLoading } = useQuery("delivery-points", () =>
    getDeliveryPoints(cookies["sofresh-admin-token"])
  );

  const columns = [
    {
      name: "Name",
      selector: (deliveryPoints: IGetDeliveryPointsData) => deliveryPoints.name,
      sortable: true,
    },
    {
      name: "Longitude",
      selector: (deliveryPoints: IGetDeliveryPointsData) =>
        deliveryPoints.longitude,
      sortable: true,
    },
    {
      name: "Latitude",
      selector: (deliveryPoints: IGetDeliveryPointsData) =>
        deliveryPoints.latitude,
      sortable: true,
    },
    {
      name: "Price",
      selector: (deliveryPoints: IGetDeliveryPointsData) =>
        deliveryPoints.price,
      sortable: true,
    },
    {
      name: "Outlet",
      selector: (deliveryPoints: IGetDeliveryPointsData) =>
        deliveryPoints.store_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (deliveryPoints: IGetDeliveryPointsData) =>
        deliveryPoints.is_active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">Inactive</span>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (deliveryPoints: IGetDeliveryPointsData) => (
        <div className="flex align-items-center">
          {/* <Link to={"#"}>
            <i className="bi bi-eye-fill me-2"></i>
          </Link>{" "} */}
          <button
            onClick={() =>
              handleOpenUpdateDeliveryPointModal(deliveryPoints.slug)
            }
            style={{ background: "none", outline: "none", border: "none" }}
          >
            <i className="bi bi-pencil-square me-2"></i>
          </button>{" "}
          {/* <Link to={'#'}>
            <i className='bi bi-trash-fill'></i>
          </Link> */}
        </div>
      ),
      sortable: true,
    },
  ];

  // HANDLES FOR CREATE DELIVERY POINT MODAL
  const handleOpenCreateDeliveryPointModal = () => {
    setIsCreateDeliveryPointModalOpen(true);
  };
  const handleCloseCreateDeliveryPointModal = () => {
    setIsCreateDeliveryPointModalOpen(false);
    // console.log('CLICKED')
    // console.log(isCreateProductModalOpen)
  };

  // HANDLES FOR UPDATE DELIVERY POINT MODAL
  const handleOpenUpdateDeliveryPointModal = (slug: string) => {
    setSlug(slug);
    setIsUpdateDeliveryPointModalOpen(true);
  };
  const handleCloseUpdateDeliveryPointModal = () => {
    setSlug("");
    setIsUpdateDeliveryPointModalOpen(false);
    // console.log('CLICKED')
    // console.log(isUpdateProductModalOpen)
  };

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <h1 className="h3 mb-3">Delivery Points</h1>
            <button
              onClick={handleOpenCreateDeliveryPointModal}
              className="btn btn-sm btn-warning float-end create-sub"
            >
              Create Delivery Points <i className="bi bi-bag-plus-fill"></i>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading ? (
                  <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  deliveryPoints && (
                    <Table data={deliveryPoints.data} columns={columns} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isCreateDeliveryPointModalOpen && (
        <CreateDeliveryPointModal
          isOpen={isCreateDeliveryPointModalOpen}
          onClose={handleCloseCreateDeliveryPointModal}
        />
      )}

      {isUpdateDeliveryPointModalOpen && (
        <UpdateDeliveryPointModal
          isOpen={isUpdateDeliveryPointModalOpen}
          onClose={handleCloseUpdateDeliveryPointModal}
          slug={slug}
        />
      )}
    </main>
  );
};

export default DeliveryPoints;
