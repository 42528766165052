import React from "react";
import "./styles/app.css";
import "./styles/main.css";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";

import AppContext from "./context/AppContext";
import { QueryClient, QueryClientProvider } from "react-query";
import Toast from "./components/toast/toast";
import { CookiesProvider } from "react-cookie";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AppContext>
          <AppRoutes />
          <Toast />
        </AppContext>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
