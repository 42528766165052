// REACT DEFAULTS
import { ChangeEvent, FC, FormEvent, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useMutation, useQuery, useQueryClient } from 'react-query'

// COMPONENTS, LIBs & UTILS
import Button from '../../../components/button/button'
import { getStores } from '../../../handlers/storeHandler'
import {
  getSingleDeliveryPoints,
  updateDeliveryPoint,
} from '../../../handlers/deliveryHandlers'

// IMAGES & ICONS
import { toast } from '../../../components/toast/toast'

// STYLES
import './DeliveryPoints.css'
import Modal from '../../../components/Modal'

const UpdateDeliveryPointModal: FC<IUpdateDeliveryPointModalProps> = ({
  isOpen,
  onClose,
  slug,
}) => {
  const queryClient = useQueryClient()
  const [cookies] = useCookies(['sofresh-admin-token'])
  const navigate = useNavigate()

  // GET SINGLE DELIVERY POINT DATA TO POPULATE THE FIELDS
  const { data: singleDeliveryPoints } = useQuery(`${slug}`, () =>
    getSingleDeliveryPoints({ token: cookies['sofresh-admin-token'], slug })
  )

  // GET ALL STORES TO PULATE THE OUTLET SELECT FIELDS
  const { data: storesData } = useQuery('categories', () =>
    getStores(cookies['sofresh-admin-token'])
  )

  // STORE INITIAL VALUE STATE OBJECT
  const [deliveryPointsFormData, setDeliveryPointsFormData] =
    useState<ISingleDeliveryPonitData>({
      store_id: 1,
      name: '',
      latitude: '',
      longitude: '',
      price: '',
      is_active: true,
    })

  // TO ENSURE THE INITAIL DATA IS NOT UNDEFINED OR EMPTY STRING
  useEffect(() => {
    // console.log('PRODUCTDATA: ', productData)
    singleDeliveryPoints &&
      setDeliveryPointsFormData({
        store_id: singleDeliveryPoints[0]?.store_id ?? 1,
        name: singleDeliveryPoints[0]?.name,
        latitude: singleDeliveryPoints[0].latitude,
        longitude: singleDeliveryPoints[0].longitude,
        price: singleDeliveryPoints[0].price,
        is_active: singleDeliveryPoints[0].is_active,
      })
  }, [singleDeliveryPoints])

  // HANDLE FORM CONTROLLED INPUT FIELDS
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setDeliveryPointsFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  // FORM CONTROL FOR SELECT OPTIONS INPUTS(store_id: number)
  const handleStoreIdSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    const val = Number(value)

    setDeliveryPointsFormData(prev => ({
      ...prev,
      store_id: val,
    }))
  }

  // MUTATE FORM DATA BEFORE SUBMISSION
  const { mutate: updateDeliveryPointMutation, isLoading: isSubmitting } =
    useMutation(updateDeliveryPoint, {
      onSuccess: async res => {
        // toast.success('Delivery point updated successfully')
        await queryClient.invalidateQueries(['delivery-points'])
      },
      onError: (e: string) => {
        console.error({ e })
        navigate('/deliverypoints')
        toast.error(e)
      },
    })

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // console.log('FORM VALUES: ', deliveryPointsFormData)
    try {
      updateDeliveryPointMutation({
        token: cookies['sofresh-admin-token'],
        data: deliveryPointsFormData,
        slug,
      })
      await queryClient.invalidateQueries(['productData'])
      onClose()
      toast.success('Delivery point updated successfully')
    } catch (error) {
      console.error(error)
      toast.error('Failed to update delivery point')
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Update Delivery Point`}>
      <div className='storeModal-body'>
        <form onSubmit={handleSubmit}>
          <div className='form-group name py-2'>
            <label htmlFor='name' className='form-label'>
              Name
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='name'
              name='name'
              placeholder='Enter Store Name'
              value={deliveryPointsFormData.name}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group longitude py-2'>
            <label htmlFor='longitude' className='form-label'>
              Longitude
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='longitude'
              name='longitude'
              placeholder='Enter Location'
              value={deliveryPointsFormData.longitude}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group latitude py-2'>
            <label htmlFor='latitude' className='form-label'>
              Latitude
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='latitude'
              name='latitude'
              placeholder='Enter Latitude'
              value={deliveryPointsFormData.latitude}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group price py-2'>
            <label htmlFor='price' className='form-label'>
              Price
            </label>
            <input
              className='form-control storeModalForm-field form-control-lg'
              type='text'
              id='price'
              name='price'
              placeholder='Enter Price'
              inputMode='numeric'
              pattern='[0-9]+(\.[0-9]{1,2})?'
              value={deliveryPointsFormData.price}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className='form-group py-2'>
            <label className='form-label' htmlFor='outlet'>
              Outlet
            </label>
            <select
              className='form-control form-field'
              id='outlet'
              name='outlet'
              value={deliveryPointsFormData.store_id}
              onChange={handleStoreIdSelectChange}>
              {storesData?.map((store: IGetStoresData) => (
                <option key={store.id} value={store.id}>
                  {store.store_name}
                </option>
              ))}
            </select>
          </div>

          <div className=' text-center mt-3 flex justify-center'>
            <Button formControl type='submit' loading={isSubmitting}>
              Update Delivery Point
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default UpdateDeliveryPointModal
