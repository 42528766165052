// REACT DEFAULTS
import { useState } from "react";

// LIBs & UTILS
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";
import { getProductCategories } from "../../../handlers/productHandler";

// COMPONENTS
import Table from "../../../components/table/Table";
import CreateProductCategoryModal from "./CreateProductCategoryModal";
import UpdateProductCategoryModal from "./UpdateProductCategoryModal";
import { Oval } from "react-loader-spinner";

// STYLES
import styles from "./ProductCategories.module.css";

interface IProductCategoryData {
  products: any;
  name: string;
  description: string;
  image: string;
  slug: string;
  id: number | string;
  price?: number | string;
  discount?: number | string;
  is_active: boolean;
  minimum_price: string;
}

const ProductCatgories = () => {
  const [slug, setSlug] = useState<string>("");
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);
  const ROLE = cookies["sofresh-admin-user"]?.role;
  const [
    isCreateProductCategoryModalOpen,
    setIsCreateProductCategoryModalOpen,
  ] = useState(false);
  const [
    isUpdateProductCategoryModalOpen,
    setIsUpdateProductCategoryModalOpen,
  ] = useState(false);

  const { data, isLoading } = useQuery("product-categories", () =>
    getProductCategories(cookies["sofresh-admin-token"])
  );

  const handleOpenCreateProductCategoryModal = () => {
    setIsCreateProductCategoryModalOpen(true);
  };

  const handleCloseCreateProductCategoryModal = () => {
    setIsCreateProductCategoryModalOpen(false);
  };

  // HANDLES FOR UPDATE MODAL
  const handleOpenUpdateProductCategoryModal = (slug: string) => {
    setSlug(slug);
    setIsUpdateProductCategoryModalOpen(true);
  };

  const handleCloseUpdateProductCategoryModal = () => {
    setSlug("");
    setIsUpdateProductCategoryModalOpen(false);
  };

  const columns = [
    {
      name: "Category Name",
      selector: (row: IProductCategoryData) => row.name,
      sortable: true,
    },
    {
      name: "Category Description",
      selector: (row: IProductCategoryData) => row.description,
      sortable: true,
    },
    {
      name: "Minimum Price",
      selector: (data: IProductCategoryData) => <p>{data.minimum_price}</p>,
      sortable: true,
    },
    {
      name: "Image",
      selector: (data: IProductCategoryData) => (
        <img
          src={data.image}
          alt={data.name}
          style={{ width: "40px", height: "40px" }}
        />
      ),
    },
    {
      name: "Status",
      selector: (data: IProductCategoryData) =>
        data.is_active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">Inactive</span>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row: IProductCategoryData) => (
        <div className="d-flex align-items-center">
          <button
            onClick={() => handleOpenUpdateProductCategoryModal(row.slug)}
            className={styles.updateProductCategoryBtn}>
            <i className="bi bi-pencil-square me-2"></i>
          </button>
        </div>
      ),
      omit: ROLE === "subadmin",
    },
  ];

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Oval height={50} width={50} strokeWidth={5} />
        </div>
      ) : (
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <h1 className="h3 mb-3">Product Categories</h1>
                <button
                  onClick={handleOpenCreateProductCategoryModal}
                  className="btn btn-sm btn-warning float-end create-sub">
                  <span>Create Product Category</span>{" "}
                  <i className="bi bi-bag-plus-fill"></i>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {isLoading ? (
                      <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                        <Oval
                          height="40"
                          width="40"
                          color="#495057"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <Table data={data} columns={columns} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isCreateProductCategoryModalOpen && (
            <CreateProductCategoryModal
              isOpen={isCreateProductCategoryModalOpen}
              onClose={handleCloseCreateProductCategoryModal}
            />
          )}

          {isUpdateProductCategoryModalOpen && (
            <UpdateProductCategoryModal
              isOpen={isUpdateProductCategoryModalOpen}
              onClose={handleCloseUpdateProductCategoryModal}
              slug={slug}
            />
          )}
        </main>
      )}
    </>
  );
};

export default ProductCatgories;
