// REACT DEFAULTS
import { ChangeEvent, FC, FormEvent, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

// COMPONENTS & UTILS
import { useCookies } from 'react-cookie'
import { s3Config } from '../../../../utils/s3Config'
import ReactS3Client from 'react-aws-s3-typescript'
import { createProductCategory } from '../../../../handlers/productHandler'
import { Oval } from 'react-loader-spinner'
import { toast } from '../../../../components/toast/toast'

// IMAGES & ICONS
import upload from '../../../../assets/uploadPNG.png'
import Modal from '../../../../components/Modal'

// STYLES
import styles from './CreateProductCategoryModal.module.css'

const CreateProductCategoryModal: FC<CreateProductCategoryModalProps> = ({
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const [cookies] = useCookies(['sofresh-admin-token'])
  const navigate = useNavigate()
  const [uploadLoading, setUploadLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [images, setImages] = useState<string | undefined>('')
  const fileInputRef = useRef<HTMLInputElement>(null)

  // INITIAL PRODUCTS DATA
  const [productCategory, setProductCategory] = useState<ProductCategory>({
    name: '',
    description: '',
    image: images,
    minimum_price: '',
    is_active: true,
  })

  // HANDLE PRODUCT IMAGE UPLOAD
  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'products',
    })
    try {
      const res = await s3.uploadFile(file)
      return res.location
    } catch (exception) {
      navigate('/product/categories')
      console.error(exception)
    }
  }

  const handleProductImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    setSelectedFile(file)
    setUploadLoading(true)

    try {
      const imageUrl = await handleFileUpload(file)
      setImages(imageUrl)
      setProductCategory(prevProduct => ({
        ...prevProduct,
        image: imageUrl,
      }))
    } catch (error) {
      console.error(error)
      navigate('/product/categories')
      toast.error('Failed to upload image')
    } finally {
      setUploadLoading(false)
    }
  } // END OF HANDLE PRODUCT IMAGE UPLOAD

  // FORM CONTROL FOR TEXT INPUTS
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!isOpen) return null
    const { name, value } = event.target
    setProductCategory(prev => ({ ...prev, [name]: value }))
  }

  // FORM SUBMISSION
  const { mutate: createProductMutation, isLoading } = useMutation(
    createProductCategory,
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(['product-categories'])
      },
      onError: (e: string) => {
        console.error({ e })
        navigate('/product/categories')
        toast.error(e)
      },
    }
  )

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      createProductMutation({
        token: cookies['sofresh-admin-token'],
        data: productCategory,
      })
      await queryClient.invalidateQueries(['product-categories'])
      onClose()
      navigate('/product/categories')
      toast.success('Product created successfully')
    } catch (error) {
      console.error(error)
      navigate('/product/categories')
      toast.error('Failed to create product category')
    }
  }

  return (
    <Modal
      key={`Add New Product Category`}
      title='Add New Product Category'
      subTitle='Upload image and enter information below to add extras'
      isOpen={isOpen}
      onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className={styles.modalBody}>
          <div className={`form-group py-2 ${styles.modalImageContainer}`}>
            <div>
              <img
                src={selectedFile ? URL.createObjectURL(selectedFile) : upload}
                alt='preview'
                width={selectedFile ? '100%' : undefined}
                height={selectedFile ? '100%' : undefined}
                onClick={handleUploadBtnClick}
                className={styles.uploadIcon}
              />
              <input
                type='file'
                id='uploadProductImage'
                accept='image/*'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleProductImageUpload}
              />
              {uploadLoading && (
                <Oval wrapperClass='ml-2' height={20} width={20} />
              )}
            </div>
            <p className={styles.modalImageText}>
              <label htmlFor='uploadProductImage'>
                <span className={styles.modalImageSpan}>Click to upload </span>{' '}
              </label>
              or drag and drop image
            </p>
            <p className={styles.modalImageText}>
              PNG, JPEG (image size should not exceed 400x400)
            </p>
          </div>

          <div className='form-group py-2'>
            <label className={styles.formLabel} htmlFor='name'>
              Name
            </label>
            <input
              type='text'
              className={`form-control ${styles.formField}`}
              id='name'
              name='name'
              value={productCategory.name}
              onChange={handleInputChange}
              placeholder='Enter name of product Category'
              required
            />
          </div>

          <div className='form-group py-2'>
            <label className={styles.formLabel} htmlFor='description'>
              Description
            </label>
            <textarea
              className={`form-control ${styles.formField}`}
              id='description'
              name='description'
              value={productCategory.description}
              onChange={handleInputChange}
              placeholder='Enter description'
              required
            />
          </div>

          <div className='form-group py-2'>
            <label className={styles.formLabel} htmlFor='minimum_price'>
              Minimum Price
            </label>
            <input
              type='text'
              className={`form-control ${styles.formField}`}
              id='minimum_price'
              name='minimum_price'
              value={productCategory.minimum_price}
              onChange={handleInputChange}
              placeholder='Enter Minimum Price'
              inputMode='numeric'
              pattern='[0-9]+(\.[0-9]{1,2})?'
              required
            />
          </div>
        </div>

        <div className={`modal-footer pt-4 ${styles.btnContainer}`}>
          <button
            type='submit'
            className={`btn form-control ${styles.btnColor}`}>
            {isLoading ? (
              <Oval color='white' wrapperClass='ml-2' height={10} width={10} />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </form>
    </Modal>
  )
}
export default CreateProductCategoryModal
