import { useEffect, ReactElement, ReactFragment, ReactNode } from 'react'
import { IoMdClose } from 'react-icons/io'
import styles from './Modal.module.css'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  subTitle?: string
  children: ReactElement | ReactNode | ReactFragment
}

const Modal = ({ children, onClose, title, subTitle, isOpen }: ModalProps) => {
  // USEEFFECT TO FREE BACKGROUND SCROLLING WHEN NAV IS OPEN ON MOBILE
  useEffect(() => {
    if (isOpen) {
      window.document.body.style.overflowY = 'hidden'
    } else {
      window.document.body.style.overflowY = 'scroll'
    }
  }, [isOpen])

  const handleClose = () => {
    onClose()
    window.document.body.style.overflowY = 'scroll'
  }

  return (
    <section className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderTop}>
            <div className={styles.modalTitle}>
              <h5 className={styles.modalHeaderTitle}>{title}</h5>
            </div>
            <button onClick={handleClose} className={styles.modalCloseBtn}>
              <IoMdClose />
            </button>
          </div>

          {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
        </div>

        <div className={styles.modalBody}>{children}</div>
      </div>
    </section>
  )
}

export default Modal
