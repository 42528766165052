import { axiosClient } from '../utils/axiosClient'

export const getExtras = async (token: string) => {
  try {
    const response = await axiosClient.get(`/extras?page_size=5000`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return response.data.data
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}

export const createExtraHandler = async ({ data, token }: any) => {
  try {
    const response = await axiosClient.post(`/extras`, data, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })

    return response
  } catch (error: any) {
    console.log({ error })
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0]
    }
    if (error?.response?.data?.error?.price) {
      throw error?.response?.data?.error.price[0]
    }
    if (error?.response?.data?.error?.product_id) {
      throw error?.response?.data?.error.product_id[0]
    }
    if (error?.response?.data?.error?.quantity) {
      throw error?.response?.data?.error.quantity[0]
    }
    if (error?.response?.data?.error?.image) {
      throw error?.response?.data?.error.image[0]
    }
    throw new Error('An error occured. Please try again.')
  }
}

export const updateExtraHandler = async ({ data, token, slug }: any) => {
  try {
    const response = await axiosClient.put(`/extras/${slug}`, data, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })

    return response
  } catch (error: any) {
    console.log({ error })
    if (error?.response?.data?.error?.name) {
      throw error?.response?.data?.error.name[0]
    }
    if (error?.response?.data?.error?.price) {
      throw error?.response?.data?.error.price[0]
    }
    if (error?.response?.data?.error?.product_id) {
      throw error?.response?.data?.error.product_id[0]
    }
    if (error?.response?.data?.error?.quantity) {
      throw error?.response?.data?.error.quantity[0]
    }
    if (error?.response?.data?.error?.image) {
      throw error?.response?.data?.error.image[0]
    }
    throw new Error('An error occured. Please try again.')
  }
}

export const getSingleExtras = async ({ token, slug }: any) => {
  try {
    const response = await axiosClient.get(`/extras/${slug}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
    return response.data.data
  } catch (error: any) {
    console.log({ error })
    throw new Error('An error occured. Please try again.')
  }
}
