import { Oval } from "react-loader-spinner";

const Button: React.FC<IButton> = ({
  children,
  loading,
  title,
  disabled,
  formControl,
  className,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`btn btn-lg btn-warning py-2 px-4 d-flex justify-content-center  align-items-center ${
        formControl && "form-control"
      } ${className && className}`}
      disabled={loading || disabled}>
      {title && <span>{title}</span>}
      {children}
      {loading && (
        <Oval wrapperClass="ml-2" color="#fff" height={20} width={20} />
      )}
    </button>
  );
};

export default Button;
