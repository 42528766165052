import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Form, Formik, ErrorMessage } from "formik";
import { Oval } from "react-loader-spinner";
import { s3Config } from "../../../utils/s3Config";
import ReactS3Client from "react-aws-s3-typescript";
import Button from "../../../components/button/button";
import Input from "../../../components/input/input";
import { createPlanTypeValidationSchema } from "../../../utils/validation";
import { useCookies } from "react-cookie";
import {
  getPlans,
  updateMealPlan,
  getSingleMealPlan,
} from "../../../handlers/productHandler";
import { toast } from "../../../components/toast/toast";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout";

const renderError = (message: string) => (
  <span style={{ color: "red", fontSize: 14, marginTop: 10 }}>{message}</span>
);

const EditPlanType = () => {
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [durationCount, setDurationCount] = useState(1);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const { data: planData } = useQuery("plans", () =>
    getPlans(cookies["sofresh-admin-token"])
  );

  const { data: planTypeData, isLoading: isPlanTypeDataLoading } = useQuery(
    `${slug}-plan-type`,
    () => getSingleMealPlan({ token: cookies["sofresh-admin-token"], slug })
  );

  useEffect(() => {
    if (planTypeData && planTypeData[0]?.duration) {
      setDurationCount(planTypeData[0]?.duration?.length);
    }
  }, [planTypeData]);

  const { mutate: updateMealPlanMutation, isLoading } = useMutation(
    updateMealPlan,
    {
      onSuccess: async res => {
        // console.log({ res })
        toast.success("Meal plan created successfully");
        await queryClient.invalidateQueries(["meal-plans"]);
        navigate("/plan-types");
      },
      onError: (e: string) => {
        console.error({ e });
        toast.error(e);
      },
    }
  );

  const initialValues = {
    name: (planTypeData && planTypeData[0]?.name) || "",
    image: (planTypeData && planTypeData[0]?.image[0]) || imgUrl,
    description: (planTypeData && planTypeData[0]?.description) || "",
    plan_id: (planTypeData && planTypeData[0]?.plan?.id) || "",
    duration: (planTypeData && planTypeData[0]?.duration) || [
      {
        days: "",
        price: "",
        pdf_url: "",
      },
    ],
    status: (planTypeData && planTypeData[0]?.status) || "true", 
  };

  const addDurationCount = () => {
    setDurationCount(e => e + 1);
  };
  const removeDurationCount = () => {
    setDurationCount(e => e - 1);
  };

  const handleFileInput = async (e: any, setFieldValue: any, idx: number) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "stores",
    });
    try {
      const res = await s3.uploadFile(file);
      // console.log({ res })
      // setPdfUrls(res.location)
      setFieldValue(`duration.${idx}.pdf_url`, res.location);
    } catch (exception) {
      console.error(exception);
    }
    setUploadLoading(false);
  };

  const handleImageInput = async (e: any) => {
    const file = e.target.files[0];

    setImageUploadLoading(true);

    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "meal-plans",
    });
    try {
      const res = await s3.uploadFile(file);
      setImgUrl(res.location);
    } catch (exception) {
      console.error(exception);
    }

    setImageUploadLoading(false);
  };

  const handleCreate = (values: any) => {
    const data = {
      ...values,
      image: imgUrl ? [imgUrl] : planTypeData && planTypeData[0]?.image,
      duration: values?.duration?.slice(0, durationCount),
      is_active: values.status === "true" ? true : false,
    };
    updateMealPlanMutation({
      token: cookies["sofresh-admin-token"],
      data,
      slug,
    });
  };

  const durationArr = Array.from(Array(durationCount).keys());
  return (
    <DashboardLayout>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12">
              <h1 className="h3 mb-3">Edit Plan Type</h1>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="card">
                <div className="card-body">
                  {isPlanTypeDataLoading ? (
                    <div className="my-8 w-100 d-flex  align-items-center justify-content-center">
                      <Oval
                        height="40"
                        width="40"
                        color="#495057"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="m-sm-4">
                      <Formik
                        initialValues={initialValues}
                        onSubmit={values => handleCreate(values)}
                        validationSchema={createPlanTypeValidationSchema}
                        enableReinitialize={true}>
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          setFieldValue,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label">
                                Meal Plan Name
                              </label>
                              <Input
                                className="form-control form-control-lg"
                                type="text"
                                name="name"
                                placeholder="Enter product name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <Input
                                className="form-control form-control-lg"
                                type="text"
                                placeholder="Enter a description"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Plan Images
                              </label>
                              <div className="flex align-items-center ">
                                <input
                                  className="form-control form-control-sm"
                                  id="formFileSm"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageInput}
                                />
                                {imageUploadLoading && (
                                  <Oval
                                    wrapperClass="ml-2"
                                    height={20}
                                    width={20}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Plan Type</label>
                              <select
                                className="form-select form-control form-control-lg"
                                name="plan_id"
                                value={values.plan_id}
                                onChange={handleChange}
                                required={true}>
                                <option>Select a plan</option>
                                {planData?.map((item: any, index: number) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name="plan_id"
                                render={renderError}
                              />
                            </div>
                            <div style={{ marginTop: 30 }}>
                              <div className="flex align-items-center justify-between w-full">
                                <div>
                                  <label className="form-label">Day(s)</label>
                                </div>

                                <div className="flex align-items-center justify-center">
                                  <button
                                    type="button"
                                    className="meal-duration-btn-minus"
                                    onClick={removeDurationCount}
                                    disabled={durationCount === 1}>
                                    -
                                  </button>
                                  <p className="meal-duration-text">
                                    {durationCount}
                                  </p>
                                  <button
                                    type="button"
                                    className="meal-duration-btn-plus"
                                    onClick={addDurationCount}>
                                    +
                                  </button>
                                </div>
                              </div>

                              {durationArr?.map((_, idx: number) => (
                                <div
                                  className="meal-duration-container"
                                  key={idx}>
                                  <div className="mb-3">
                                    <label className="form-label">Days</label>
                                    <Input
                                      className="form-control form-control-lg"
                                      type="number"
                                      placeholder="Enter days"
                                      name={`duration.${idx}.days`}
                                      value={values?.duration[idx]?.days}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Price</label>
                                    <Input
                                      className="form-control form-control-lg"
                                      type="number"
                                      placeholder="Enter price"
                                      name={`duration.${idx}.price`}
                                      value={values?.duration[idx]?.price}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Pdf</label>
                                    <div className="flex align-items-center ">
                                      <input
                                        className="form-control form-control-sm"
                                        id="pdf_file"
                                        type="file"
                                        name="file"
                                        accept="application/pdf"
                                        onChange={e =>
                                          handleFileInput(e, setFieldValue, idx)
                                        }
                                      />
                                      {uploadLoading && (
                                        <Oval
                                          wrapperClass="ml-2"
                                          height={20}
                                          width={20}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select
                                  className="form-select form-control form-control-lg"
                                  name="status"
                                  value={values.status}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  required={true}
                                >
                                  <option value="true">Active</option>
                                  <option value="false">Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div className="text-center mt-3 flex justify-center">
                              <Button type="submit" loading={isLoading}>
                                Edit Plan
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default EditPlanType;
