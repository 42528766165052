// REACT & DEFAULTS
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
// import { FormEvent, useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../context/AppContext";
import { useCookies } from "react-cookie";
import { useMutation, useQuery, useQueryClient } from "react-query";

// COMPONENTS, UTILs & LIBs
import {
  UserAccountData,
  getSingleUserAccount,
  UpdateSingleUserAccountHandler,
} from "../../../../handlers/userAccounts";
import { Oval } from "react-loader-spinner";
import { toast } from "../../../../components/toast/toast";

const UpdateSingleUserAccount = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [userID, setUserID] = useState<string>("");
  const { user } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);

  // Effect to set userID when id changes
  useEffect(() => {
    if (id) {
      setUserID(id);
    }
  }, [id]);

  // Query for user account data
  const { data: userAccount, isLoading: isUserAccountDataLoading } =
    useQuery<UserAccountData>(`user-account-${id}`, () =>
      getSingleUserAccount({
        token: cookies["sofresh-admin-token"],
        id: id ?? userID,
      })
    );

  // STORE INITIAL VALUE STATE OBJECT
  const [userAccountDataFormData, setUserAccountDataFormData] =
    useState<UserAccountData>({
      id: null,
      name: "",
      gender: "",
      phone_no: "",
      email: "",
      role: "",
      is_active: true,
      store: "",
      meta: {
        allergies: "",
        expectant_mum: "",
      },
    });

  useEffect(() => {
    userAccount &&
      setUserAccountDataFormData({
        id: userAccount?.id,
        name: userAccount?.name,
        gender: userAccount?.gender,
        phone_no: userAccount?.phone_no,
        email: userAccount?.email,
        role: userAccount?.role,
        is_active: userAccount?.is_active,
        store: userAccount?.store,
        meta: {
          allergies: userAccount?.meta?.allergies,
          expectant_mum: userAccount?.meta?.expectant_mum,
        },
      });
  }, [userAccount]);

  // HANDLE FORM CONTROLLED INPUT FIELDS
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setUserAccountDataFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // MUTATE FORM DATA BEFORE SUBMISSION
  const {
    mutate: UpdateSingleUserAccountMutation,
    isLoading: isMutationLoading,
  } = useMutation(UpdateSingleUserAccountHandler, {
    onSuccess: async res => {
      await queryClient.invalidateQueries([`user-account-${id}`]);
      toast.success("Product updated successfully");
    },
    onError: (e: string) => {
      console.error({ e });
      toast.error("Failed to update product");
    },
  });

  // HANDLE FORM SUBMISSION
  const handleUpdateUserAccount = async () => {
    // const handleUpdateUserAccount = async (event: FormEvent<HTMLFormElement>) => {
    // event.preventDefault()
    console.log("Update User Account Initiated");
    try {
      UpdateSingleUserAccountMutation({
        token: cookies["sofresh-admin-token"],
        userID: id ?? userID,
        userAccountDetails: userAccountDataFormData,
      });
      // await queryClient.invalidateQueries([`user-account-${id}`])
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <h1 className="w-100 h3 mb-4">
          <strong>Update Customer Information</strong>
        </h1>

        {user?.role === "admin" && (
          <div className="row">
            <div className="col-12 d-flex">
              <div className="card flex-fill">
                {isUserAccountDataLoading ? (
                  <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      {userAccount && (
                        <div className="row">
                          <div className="card">
                            <div className="card-body">
                              <div className="m-sm-4">
                                <form
                                  onSubmit={handleUpdateUserAccount}
                                  className="form">
                                  {/* <div className='form-group name py-2'>
                                      <label
                                        htmlFor='name'
                                        className='form-label'>
                                        Name
                                      </label>
                                      <input
                                        className='form-control storeModalForm-field form-control-lg'
                                        type='text'
                                        id='name'
                                        name='name'
                                        placeholder='Enter Name'
                                        value={userAccountDataFormData.name}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div> */}

                                  {/* <div className='form-group email py-2'>
                                      <label
                                        htmlFor='email'
                                        className='form-label'>
                                        Email
                                      </label>
                                      <input
                                        className='form-control storeModalForm-field form-control-lg'
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder='Enter Email'
                                        value={userAccountDataFormData.email}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div> */}

                                  {/* <div className='form-group phone_no py-2'>
                                      <label
                                        htmlFor='phone_no'
                                        className='form-label'>
                                        Phone Number
                                      </label>
                                      <input
                                        className='form-control storeModalForm-field form-control-lg'
                                        type='text'
                                        id='phone_no'
                                        name='phone_no'
                                        placeholder='Enter Phone Number'
                                        // inputMode='numeric'
                                        value={userAccountDataFormData.phone_no}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div> */}

                                  <div className="row row-cols-1 row-cols-md-2">
                                    <div className="col d-flex flex-column allergies py-2">
                                      <label
                                        htmlFor="name"
                                        className="form-label">
                                        Name
                                      </label>
                                      <input
                                        className="form-control storeModalForm-field form-control-lg"
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter Name"
                                        value={userAccountDataFormData.name}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>

                                    <div className="col d-flex flex-column allergies py-2">
                                      <label
                                        htmlFor="gender"
                                        className="form-label">
                                        Gender
                                      </label>
                                      <select
                                        className="form-control form-field form-control-lg"
                                        id="gender"
                                        name="gender"
                                        value={userAccountDataFormData.gender}
                                        onChange={handleChange}>
                                        <optgroup label="Select Gender">
                                          <option value={"male"}>Male</option>
                                          <option value={"female"}>
                                            FeMale
                                          </option>
                                        </optgroup>
                                      </select>
                                      {/* <input
                                          className='form-control storeModalForm-field form-control-lg'
                                          type='text'
                                          id='gender'
                                          name='gender'
                                          placeholder='Enter Gender'
                                          value={userAccountDataFormData.gender}
                                          onChange={handleChange}
                                          required
                                        /> */}
                                    </div>
                                  </div>

                                  <div className="row row-cols-1 row-cols-md-2">
                                    <div className="col d-flex flex-column allergies py-2">
                                      <label
                                        htmlFor="email"
                                        className="form-label">
                                        Email
                                      </label>
                                      <input
                                        className="form-control storeModalForm-field form-control-lg"
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        value={userAccountDataFormData.email}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>

                                    <div className="col d-flex flex-column allergies py-2">
                                      <label
                                        htmlFor="phone_no"
                                        className="form-label">
                                        Phone Number
                                      </label>
                                      <input
                                        className="form-control storeModalForm-field form-control-lg"
                                        type="text"
                                        id="phone_no"
                                        name="phone_no"
                                        placeholder="Enter Phone Number"
                                        // inputMode='numeric'
                                        value={userAccountDataFormData.phone_no}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="row row-cols-1 row-cols-md-2">
                                    <div className="col d-flex flex-column allergies py-2">
                                      <label
                                        htmlFor="allergies"
                                        className="form-label">
                                        Health Concerns
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control storeModalForm-field form-control-lg"
                                        id="allergies"
                                        name="allergies"
                                        placeholder="Enter Health Concerns(if any)"
                                        value={
                                          userAccountDataFormData.meta
                                            ?.allergies
                                        }
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>

                                    <div className="col d-flex flex-column expectant_mum py-2">
                                      <label
                                        htmlFor="expectant_mum"
                                        className="form-label">
                                        Expectant Mum?
                                      </label>
                                      {/* <input
                                          type='text'
                                          className='form-control storeModalForm-field form-control-lg'
                                          id='expectant_mum'
                                          name='expectant_mum'
                                          placeholder='Expectant Mum?'
                                          value={
                                            userAccountDataFormData.meta
                                              ?.expectant_mum
                                          }
                                          onChange={handleChange}
                                          required
                                        /> */}
                                      <select
                                        className="form-control form-field form-control-lg"
                                        id="expectant_mum"
                                        name="expectant_mum"
                                        value={
                                          userAccountDataFormData.meta
                                            ?.expectant_mum
                                        }
                                        onChange={handleChange}>
                                        <option value={"yes"}>Yes</option>
                                        <option value={"no"}>No</option>
                                      </select>
                                    </div>
                                  </div>

                                  {/* <div className='form-row d-flex gap-4'>
                                      <div className='form-group col-md-6 allergies py-2'>
                                        <label
                                          htmlFor='allergies'
                                          className='form-label'>
                                          Health Concerns
                                        </label>
                                        <input
                                          className='form-control storeModalForm-field form-control-lg'
                                          type='text'
                                          id='allergies'
                                          name='allergies'
                                          placeholder='Enter Health Concerns(if any)'
                                          value={
                                            userAccountDataFormData.meta
                                              ?.allergies
                                          }
                                          onChange={handleChange}
                                          required
                                        />
                                      </div>

                                      <div className='form-group col-md-6 expectant_mum py-2'>
                                        <label
                                          htmlFor='expectant_mum'
                                          className='form-label'>
                                          Expectant Mum?
                                        </label>
                                        <input
                                          className='form-control storeModalForm-field form-control-lg'
                                          type='text'
                                          id='expectant_mum'
                                          name='expectant_mum'
                                          placeholder='Expectant Mum?'
                                          value={
                                            userAccountDataFormData.meta
                                              ?.expectant_mum
                                          }
                                          onChange={handleChange}
                                          required
                                        />
                                      </div>
                                    </div> */}

                                  <button
                                    type="submit"
                                    disabled={isMutationLoading}>
                                    Update Information
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default UpdateSingleUserAccount;
