import Button from "../../../components/button/button";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "../../../components/toast/toast";
import { createNavigationButton } from "../../../handlers/navigationHandler";
import { useCookies } from "react-cookie";
import { ErrorMessage, Form, Formik } from "formik";
import { createButtonValidationSchema } from "../../../utils/validation";

const CreateNavigationButton = () => {
  let initialValues = {
    url: "",
    title: "",
    status: "true",
  };

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const { mutate: createNavigationButtonMutation, isLoading } = useMutation(
    createNavigationButton,
    {
      onSuccess: async (res) => {
        toast.success("button created successfully");
        await queryClient.invalidateQueries(["sub-admin"]);
        navigate("/navigation-buttons");
      },
      onError: (e: string) => {
        console.error({ e });
        navigate("/navigation-buttons");
        toast.error(e);
      },
    }
  );

  const handleSubmit = (values: any) => {
    const data = {
      url: values.url,
      title: values.title,
      is_active: values.status === "true" ? true : false,
    };

    // console.log("Submitting data:", data);
    createNavigationButtonMutation({
      data: data,
      token: cookies["sofresh-admin-token"],
    });
  };

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex items-center justify-content-between">
              <h1 className="h3">Create New Button</h1>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card p-5">
              <Formik
                initialValues={initialValues}
                validationSchema={createButtonValidationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row mb-4">
                      <div className="col">
                        <label className="form-label">Button Name</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          required={true}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">Url Link</label>
                        <input
                          className="form-control form-control-lg"
                          type="url"
                          name="url"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.url}
                          required={true}
                        />
                        <ErrorMessage
                          name="url"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label className="form-label">Status</label>
                        <select
                          className="form-select form-control form-control-lg"
                          name="status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.status}
                          required={true}
                        >
                          <option>Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="text-center mt-3 flex justify-center align-items-center">
                      <Button type="submit" loading={isLoading}>
                        Create New Button
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateNavigationButton;
