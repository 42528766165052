import { Link } from "react-router-dom";
import backBtn from "../../../assets/back.svg";
import Button from "../../../components/button/button";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLocation,
  updateLocation,
} from "../../../handlers/locationHandlers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "../../../components/toast/toast";
import { addLocationValidationSchema } from "../../../utils/validation";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../../../utils/s3Config";
import { Oval } from "react-loader-spinner";
import upload from "../../../assets/uploadPNG.png";
import { useCookies } from "react-cookie";

// STYLES
import styles from "./EditLocation.module.css";

const EditLocation = () => {
  const [image, setImage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [initialValues, setInitialValues] = useState({
    name: "",
    phone_no: "",
    address: "",
    delivery_option: "pickup",
  });
  const { slug: locationSlug } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [cookies] = useCookies(["sofresh-admin-token"]);

  const { data: locationData } = useQuery(`${locationSlug}-location`, () =>
    getLocation<IStoreLocationData>({
      token: cookies["sofresh-admin-token"],
      slug: locationSlug as string,
    })
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "locations",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      console.error(exception);
    }
  };

  const handlePlanImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setUploadLoading(true);

    try {
      const imageUrl = await handleFileUpload(file);
      setImage(imageUrl as string);
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    } finally {
      setUploadLoading(false);
    }
  };

  const handleSetActive = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "true") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const { mutate: updateLocationMutation, isLoading } = useMutation(
    updateLocation,
    {
      onSuccess: async (res) => {
        toast.success("Location updated successfully");
        await queryClient.invalidateQueries(["locations"]);
        navigate("/store-locator");
      },
      onError: (e) => {
        console.error({ e });
        toast.error("Error! Please try again");
      },
    }
  );

  const handleSubmit = async (values: any) => {
    if (image) {
      setImageErr(false);
      const payload = {
        ...values,
        image,
        is_active: isActive,
      };

      updateLocationMutation({
        token: cookies["sofresh-admin-token"],
        data: payload,
        slug: locationSlug as string,
      });
    } else {
      setImageErr(true);
    }
  };

  useEffect(() => {
    if (locationData) {
      setImage(locationData.image);
      setIsActive(locationData?.is_active);
      setInitialValues({
        name: locationData?.name,
        phone_no: locationData?.phone_no,
        address: locationData?.address,
        delivery_option: locationData?.delivery_option,
      });
    }
  }, [locationData]);

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <Link
              to={"/store-locator"}
              className="d-flex align-middle gap-2"
              style={{ color: "#667185" }}
            >
              <img src={backBtn} alt="back" />
              <span className="mt-2">Go Back</span>
            </Link>
            <div className="d-flex items-center justify-content-between mt-4">
              <h1 className="h3">Edit Store Location</h1>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={addLocationValidationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                errors,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  className="flex-column w-full align-items-center"
                >
                  <div className="card p-5">
                    <div className="row mb-4">
                      <div className="col">
                        <label className="form-label">Outlet Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter name of outlet"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && (
                          <p className="error-validation-text">
                            {`${errors.name}`}
                          </p>
                        )}
                      </div>
                      <div className="col">
                        <label className="form-label">
                          Outlet Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter phone number of outlet"
                          name="phone_no"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone_no}
                        />
                        {errors.phone_no && (
                          <p className="error-validation-text">
                            {`${errors.phone_no}`}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label className="form-label">Delivery Options</label>
                        <select
                          className="form-select form-select-lg"
                          name="delivery_option"
                          placeholder="Delivery Options"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.delivery_option}
                        >
                          <option value="pickup">Pickup</option>
                          <option value="delivery">Delivery</option>
                        </select>

                        {errors.delivery_option && (
                          <p className="error-validation-text">
                            {`${errors.delivery_option}`}
                          </p>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">Status</label>
                        <select
                          className="form-select form-select-lg"
                          name="is_active"
                          placeholder="Delivery Options"
                          onChange={handleSetActive}
                          onBlur={handleBlur}
                          value={isActive ? "true" : "false"}
                        >
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Outlet Address</label>
                      <textarea
                        className="form-control form-control-lg"
                        placeholder="Enter Address of Outlet"
                        rows={5}
                        name="address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      ></textarea>

                      {errors.address && (
                        <p className="error-validation-text">
                          {`${errors.address}`}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <div
                        className={`form-group py-2 ${styles.modalImageContainer}`}
                      >
                        <div>
                          {uploadLoading ? (
                            <Oval wrapperClass="ml-2" height={20} width={20} />
                          ) : (
                            <img
                              src={
                                selectedFile
                                  ? URL.createObjectURL(selectedFile)
                                  : locationData
                                  ? locationData?.image
                                  : upload
                              }
                              alt="preview"
                              onClick={handleUploadBtnClick}
                              className={
                                selectedFile || locationData?.image
                                  ? styles.imageContainer
                                  : styles.uploadIcon
                              }
                            />
                          )}
                          <input
                            type="file"
                            id="uploadPlanImage"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handlePlanImageUpload}
                          />
                        </div>
                        <p className={styles.modalImageText}>
                          <label htmlFor="uploadPlanImage">
                            <span className={styles.modalImageSpan}>
                              Click to upload{" "}
                            </span>{" "}
                          </label>
                          or drag and drop image
                        </p>
                        <p className={styles.modalImageText}>
                          PNG, JPEG (image size should not exceed 400x400)
                        </p>
                      </div>
                      {imageErr && (
                        <p className="error-validation-text">
                          Please upload an image!
                        </p>
                      )}
                    </div>

                    <div className="text-center mt-3 flex justify-center align-items-center">
                      <Button type="submit">
                        {isLoading ? (
                          <div className="d-flex gap-2">
                            <Oval
                              color="white"
                              wrapperClass="ml-2"
                              height={15}
                              width={15}
                            />
                            Updating
                          </div>
                        ) : (
                          "Update Location"
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditLocation;
