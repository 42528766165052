// REACT DEFAULTS
import { ChangeEvent, FC, FormEvent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useCookies } from 'react-cookie'
import ReactS3Client from 'react-aws-s3-typescript'

// COMPONENTS & UTILS
import Modal from '../../../../components/Modal'
import { s3Config } from '../../../../utils/s3Config'
import {
  createProductHandler,
  getProductCategories,
} from '../../../../handlers/productHandler'
import { toast } from '../../../../components/toast/toast'
import { getExtras } from '../../../../handlers/extraHandler'
import { Oval } from 'react-loader-spinner'

// IMAGES & ICONS
import upload from '../../../../assets/uploadPNG.png'

// STYLES
import styles from './CreateProductsModal.module.css'

const CreateProductModal: FC<CreateProductModalProps> = ({
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const [cookies] = useCookies(['sofresh-admin-token'])
  const navigate = useNavigate()
  const [uploadLoading, setUploadLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [images, setImages] = useState<(string | undefined)[]>([])
  const [selectedOptions, setSelectedOptions] = useState<number[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)

  // GET PRODUCT CATEGORY SELECT DATA
  const { data: product_category } = useQuery<ICategories[]>('categories', () =>
    getProductCategories(cookies['sofresh-admin-token'])
  )
  // console.log('product_category: ', product_category)

  // GET EXTRAS CHECKBOX DATA
  const { data: extrasOptions } = useQuery<IExtras[]>('extras', () =>
    getExtras(cookies['sofresh-admin-token'])
  )
  // console.log('EXTRAS-DATA: ', extrasOptions)

  // INITIAL PRODUCTS DATA
  const [product, setProduct] = useState<Product>({
    image: images,
    name: '',
    price: '',
    description: '',
    extra_id: selectedOptions,
    category_id: 1,
    is_active: true,
  })
  // console.log('ProductCAT: ', product_category)

  // HANDLE PRODUCT IMAGE UPLOAD
  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'products',
    })
    try {
      const res = await s3.uploadFile(file)
      return res.location
    } catch (exception) {
      console.error(exception)
    }
  }

  const handleProductImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    setSelectedFile(file)
    setUploadLoading(true)

    try {
      const imageUrl = await handleFileUpload(file)
      setImages([imageUrl])
      setProduct(prevProduct => ({
        ...prevProduct,
        image: [imageUrl],
      }))
    } catch (error) {
      console.error(error)
      toast.error('Failed to upload image')
    } finally {
      setUploadLoading(false)
    }
  } // END OF HANDLE PRODUCT IMAGE UPLOAD

  // FORM CONTROL FOR TEXT INPUTS
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!isOpen) return null
    const { name, value } = event.target
    setProduct(prevProduct => ({ ...prevProduct, [name]: value }))
  }

  // FORM CONTROL FOR SELECT OPTIONS INPUTS(product_categories: number)
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    const val = Number(value)
    // console.log('CATEGORY_ID: ', val)
    setProduct(prevProduct => ({ ...prevProduct, category_id: val }))
  }

  // FORM CONTROL FOR CHECKBOXES(extra_id: [])
  const handleCheckboxChange = (option: number) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(o => o !== option)
      : [...selectedOptions, option]
    setSelectedOptions(updatedOptions)
    // console.log('UPDATED OPTIONS: ', updatedOptions)

    setProduct(prevProduct => ({ ...prevProduct, extra_id: updatedOptions }))
  }

  // FORM SUBMISSION
  const { mutate: createProductMutation, isLoading } = useMutation(
    createProductHandler,
    {
      onSuccess: async res => {
        // console.log({ res })
        toast.success('Product created successfully')
        await queryClient.invalidateQueries(['products'])
        onClose()
      },
      onError: (e: string) => {
        console.error({ e })
        navigate('/product')
        toast.error(e)
      },
    }
  )

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // onSubmit(product)
    // console.log('PRODUCT: ', product)
    try {
      createProductMutation({
        token: cookies['sofresh-admin-token'],
        data: product,
      })

      // toast.success('Product created successfully')
      await queryClient.invalidateQueries(['products'])
      onClose()
    } catch (error) {
      console.error(error)
      toast.error('Failed to create product')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Add New Product'
      subTitle={'Upload image and enter information below to add extras'}>
      <form onSubmit={handleSubmit}>
        <div className={styles.modalBody}>
          <div className={`py-2 form-group ${styles.modalImageContainer}`}>
            <div>
              {uploadLoading ? (
                <Oval wrapperClass='ml-2' height={20} width={20} />
              ) : (
                <img
                  src={
                    selectedFile ? URL.createObjectURL(selectedFile) : upload
                  }
                  alt='preview'
                  width={selectedFile ? '100%' : undefined}
                  height={selectedFile ? '100%' : undefined}
                  onClick={handleUploadBtnClick}
                  className={styles.uploadIcon}
                />
              )}
              <input
                type='file'
                id='uploadProductImage'
                accept='image/*'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleProductImageUpload}
              />
            </div>
            <p className={styles.modalImageText}>
              <label htmlFor='uploadProductImage'>
                <span className={styles.modalImageSpan}>Click to upload </span>{' '}
              </label>
              or drag and drop image
            </p>
            <p className={styles.modalImageText}>
              PNG, JPEG (image size should not exceed 400x400)
            </p>
          </div>

          <div className={`py-2 form-group`}>
            <label className={`form-label ${styles.formLabel}`} htmlFor='name'>
              Name
            </label>
            <input
              type='text'
              className={`form-control ${styles.formField}`}
              id='name'
              name='name'
              value={product.name}
              onChange={handleInputChange}
              placeholder='Enter name of product'
              required
            />
          </div>
          <div className={`py-2 form-group`}>
            <label className={`form-label ${styles.formLabel}`} htmlFor='price'>
              Price
            </label>
            <input
              type='text'
              className={`form-control ${styles.formField}`}
              id='price'
              name='price'
              value={product.price}
              onChange={handleInputChange}
              placeholder='Enter price '
              inputMode='numeric'
              pattern='[0-9]+(\.[0-9]{1,2})?'
              required
            />
          </div>

          <div className={`py-2 form-group`}>
            <label
              className={`form-label ${styles.formLabel}`}
              htmlFor='category'>
              Category:
            </label>
            <select
              className={`form-control ${styles.formField}`}
              id='category'
              name='category'
              value={product.category_id}
              onChange={handleSelectChange}>
              {product_category?.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className={`py-2 form-group`}>
            <label className={`form-label ${styles.formLabel}`}>Extras</label>
            <div className={styles.formCheckboxes}>
              {extrasOptions?.map(option => (
                <label key={option.id} className={styles.formCheckbox}>
                  <input
                    type='checkbox'
                    className={styles.formCheckboxIput}
                    checked={selectedOptions.includes(option.id)}
                    onChange={() => handleCheckboxChange(option.id)}
                  />
                  <span className={styles.formCheckboxLabel}>
                    {option.name}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div className={`py-2 form-group`}>
            <label
              className={`form-label ${styles.formLabel}`}
              htmlFor='description'>
              Description
            </label>
            <textarea
              className={`form-control ${styles.formField}`}
              id='description'
              name='description'
              value={product.description}
              onChange={handleInputChange}
              placeholder='Enter description'
              required
            />
          </div>
        </div>
        <div className={`pt-4 modal-footer ${styles.btnContainer}`}>
          <button
            type='submit'
            className={`btn form-control ${styles.btnColor}`}>
            {isLoading ? (
              <Oval color='white' wrapperClass='ml-2' height={10} width={10} />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </form>
    </Modal>
  )
}
export default CreateProductModal
