import React from 'react'
import { ErrorMessage } from 'formik'

const renderError = (message: string) => (
  <span style={{ color: 'red', fontSize: 14, paddingTop: 10 }}>{message}</span>
)

interface IInput {
  name: string
  type?: string
  placeholder?: string
  onBlur?: any
  onChange?: any
  value?: string
  className?: string
  required?: boolean
  id?: string
  accept?: string
  multiple?: boolean
}

const Input = ({
  name,
  type,
  placeholder,
  onBlur,
  onChange,
  value,
  className,
  required,
  id,
  accept,
  multiple,
}: IInput) => {
  return (
    <>
      <input
        name={name}
        type={type || 'text'}
        className={`${className}`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        required={required}
        accept={accept}
        id={id}
        multiple={multiple}
      />
      <ErrorMessage name={name} render={renderError} />
    </>
  )
}

export default Input
