import React from "react";
import { Link } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import { GoPencil } from "react-icons/go";
// import { CgTrash } from "react-icons/cg";
import locationDefaultImg from "../../assets/locatorimg.svg";
// import { useCookies } from "react-cookie";
// import { useMutation, useQueryClient } from "react-query";
// import { useNavigate } from "react-router-dom";
// import { deleteLocation } from "../../handlers/locationHandlers";
// import { toast } from "../../components/toast/toast";
// import { Oval } from "react-loader-spinner";

interface StoreLocatorItemProps {
  image: string;
  name: string;
  address: string;
  delivery_option: string;
  phone_no: string;
  slug: string;
  is_active: boolean;
}

const StoreLocatorItem: React.FC<StoreLocatorItemProps> = ({
  image,
  name,
  address,
  delivery_option,
  phone_no,
  slug,
  is_active,
}) => {
  // const [cookies] = useCookies(["sofresh-admin-token"]);
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();

  // const { mutate: deleteLocationMutation, isLoading } = useMutation(
  //   deleteLocation,
  //   {
  //     onSuccess: async (res) => {
  //       toast.success("Location deleted successfully");
  //       await queryClient.invalidateQueries(["locations"]);
  //       navigate("/store-locator");
  //     },
  //     onError: (e) => {
  //       console.error({ e });
  //       toast.error("Error! Please try again");
  //     },
  //   }
  // );

  // const handleDeleteLocation = () => {
  //   if (window.confirm(`Do you want to delete ${name} location?`)) {
  //     deleteLocationMutation({
  //       token: cookies["sofresh-admin-token"],
  //       slug,
  //     });
  //   } else {
  //     return;
  //   }
  // };
  return (
    <div className="col-md-6">
      {/* {isLoading ? (
        <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
          <Oval height="40" width="40" color="#495057" visible={true} />
        </div>
      ) : (
        <>
         
        </>
      )} */}

      <div className="mb-4">
        <div className="d-flex flex-row gap-4">
          <div>
            <img
              src={image ? image : locationDefaultImg}
              alt="blog banner"
              className="rounded-3"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
              }}
            />
          </div>
          <div>
            <h4 className="mb-2">{name}</h4>
            <h3 className="mb-2">{address}</h3>
            <p className="mb-1">{delivery_option}</p>
            <p>{phone_no}</p>
            <p
              style={{
                width: "fit-content",
                backgroundColor: is_active ? "#339e35" : "#d80e01",
                color: "#ffffff",
                paddingInline: "8px",
                paddingBlock: "4px",
                borderRadius: "24px",
              }}
            >
              {is_active ? "Active" : "Inactive"}
            </p>
          </div>
        </div>

        <div className="dropdown float-end position-relative">
          <HiDotsHorizontal
            className="fs-3 dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ marginTop: "-17px", color: "#70707B" }}
          />
          <ul className="dropdown-menu">
            <li>
              <Link
                className="dropdown-item"
                to={`/store-locator/${slug}/edit-location`}
              >
                <GoPencil className="me-2" /> Edit Location
              </Link>
            </li>
            {/* <li>
                  <button
                    className="dropdown-item"
                    onClick={handleDeleteLocation}
                  >
                    <CgTrash className="me-2" /> Remove Location
                  </button>
                </li> */}
          </ul>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default StoreLocatorItem;
