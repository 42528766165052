/*=============================================
=               HELPER FUNCTIONS              =
=============================================*/
// FUNCTION TO CONVERT STRING NUMs or NUMs to Nigerian Naira.
export const formatToNigerianNaira = (num: number | string): string => {
  // Convert input to a number
  const parsedNum = typeof num === "string" ? parseFloat(num) : num;

  // Check if input is valid
  if (isNaN(parsedNum)) {
    throw new Error(
      "Invalid input, please provide a number or a string of a number."
    );
  }
  // Format to Nigerian Naira currency format
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  return formatter.format(parsedNum);
};

/*----------  SORT ARRAYS IN ORDER OF LAST ID FIRST  ----------*/
export const sortFn = (a: { id: number }, b: { id: number }) => b.id - a.id;

/*----------  REMOVE STRINGS AFTER SECOND COMMA  ----------*/
export const removeStringAfterSecondComma = (inputString: string): string => {
  const commaIndex = inputString.indexOf(",");
  if (commaIndex !== -1) {
    const secondCommaIndex = inputString.indexOf(",", commaIndex + 1);
    if (secondCommaIndex !== -1) {
      return inputString.slice(0, secondCommaIndex);
    }
  }
  return inputString;
};

/*=====     End of HELPER FUNCTIONS    ======*/

export function ucfirst(str: string | any): string {
  if (typeof str === "string") {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  } else {
    return str;
  }
}

// FUNCTION TO CONVERT STRING TO BOOLEAN
export function updateDeliveryStatus(obj: ICreateStoreData): ICreateStoreData {
  const newObj: ICreateStoreData = { ...obj };
  if (typeof newObj.delivery_status === "string") {
    if (newObj.delivery_status === "true") {
      newObj.delivery_status = Boolean(true);
    } else if (newObj.delivery_status === "false") {
      newObj.delivery_status = Boolean(false);
    }
    // return newObj
  }
  return newObj;
}

// FUNCTION TO CONVERT STRING TO BOOLEAN
export function updateDeliveryPointStatus(
  obj: ICreateDeliveryPoint
): ICreateDeliveryPoint {
  const newObj: ICreateDeliveryPoint = { ...obj };
  if (typeof newObj.is_active === "string") {
    if (newObj.is_active === "true") {
      newObj.is_active = Boolean(true);
    } else if (newObj.is_active === "false") {
      newObj.is_active = Boolean(false);
    }
    // return newObj
  }
  return newObj;
}

export const scrollToTop = (duration: number = 500) => {
  const startingY = window.scrollY;
  const startTime =
    "now" in window.performance ? performance.now() : new Date().getTime();

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = (currentTime: number) => {
    const elapsedTime = currentTime - startTime;
    window.scrollTo(
      0,
      easeInOutQuad(elapsedTime, startingY, -startingY, duration)
    );

    if (elapsedTime < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};
// Example usage:
// Call scrollToTop() to scroll to the top with the default duration (500 milliseconds)
// Call scrollToTop(1000) to scroll to the top with a custom duration (1000 milliseconds)

// FORMAT TO INTERNATION DATE
export const formatDate = (rawDate: Date | string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // If rawDate is a string, convert it to a Date object
  const formattedDate =
    typeof rawDate === "string" ? new Date(rawDate) : rawDate;

  // Check if the conversion was successful before formatting
  // if (isNaN(formattedDate.getTime())) {
  //   console.error("Invalid date format");
  //   return "Invalid date";
  // }

  const formattedDateString = new Intl.DateTimeFormat("en-US", options).format(
    formattedDate
  );

  return formattedDateString;
};

// FORMAT TO INTERNATION DATE TIME
export const formatDateTime = (rawDate: Date | string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };

  // If rawDate is a string, convert it to a Date object
  const formattedDate =
    typeof rawDate === "string" ? new Date(rawDate) : rawDate;

  // Check if the conversion was successful before formatting
  if (isNaN(formattedDate.getTime())) {
    console.error("Invalid date format");
    return "Invalid date";
  }

  const formattedDateString = new Intl.DateTimeFormat("en-US", options).format(
    formattedDate
  );

  return formattedDateString;
};
