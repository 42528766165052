import { axiosClient } from '../utils/axiosClient'

interface IgetDashboardSummaryProps {
  token: string
  role: string
  time: string
}

export const getDashboardSummary = async ({
  token,
  role,
  time,
}: IgetDashboardSummaryProps) => {
  // {{sofresh-localhost}}admin/dashboard/total-order?time=week
  try {
    const response = await axiosClient.get<{ total: string }>(
      `/${
        role === 'admin' ? 'admin' : 'subadmin'
      }/dashboard/total-order?time=${time}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.total
  } catch (error: any) {
    console.error({ error })
    throw new Error('An error occured. Please try again.')
  }
}
