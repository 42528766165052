// REACT & DEFAULTS
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";

// COMPOENETS, LIBs & UTILs
import { AuthContext } from "../../context/AppContext";
import { Oval } from "react-loader-spinner";
import { getAdminOrders } from "../../handlers/orderHandler";
import { getDashboardSummary } from "../../handlers/dashboardHandlers";
import Table from "../../components/table/Table";
import { formatToNigerianNaira } from "../../utils/helper";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);

  const { data, isLoading } = useQuery(
    [`${cookies["sofresh-admin-user"]?.role}-orders`],
    () =>
      getAdminOrders({
        token: cookies["sofresh-admin-token"],
        role: cookies["sofresh-admin-user"]?.role,
      })
  );
  // console.log('ORDER_DATA: ', data)
  // console.log('ROLE: ', cookies['sofresh-admin-user']?.role)

  const { data: todayTotalOrders, isLoading: isTodayTotalOrdersLoading } =
    useQuery(
      [`dashboard-summary-today`],
      () =>
        getDashboardSummary({
          token: cookies["sofresh-admin-token"],
          role: cookies["sofresh-admin-user"]?.role,
          time: "today",
        }),
      {
        retry: 1,
      }
    );
  // console.log('Week_DATA: ', weekTotalOrders)
  // console.info('isWeekLoading_DATA: ', isweekTotalOrdersLoading)

  const { data: weekTotalOrders, isLoading: isWeekTotalOrdersLoading } =
    useQuery(
      [`dashboard-summary-week`],
      () =>
        getDashboardSummary({
          token: cookies["sofresh-admin-token"],
          role: cookies["sofresh-admin-user"]?.role,
          time: "week",
        }),
      {
        retry: 1,
      }
    );
  // console.log('Week_DATA: ', weekTotalOrders)
  // console.info('isWeekLoading_DATA: ', isweekTotalOrdersLoading)

  const { data: monthTotalOrders, isLoading: isMonthTotalOrdersLoading } =
    useQuery(
      [`dashboard-summary-month`],
      () =>
        getDashboardSummary({
          token: cookies["sofresh-admin-token"],
          role: cookies["sofresh-admin-user"]?.role,
          time: "month",
        }),
      {
        retry: 1,
      }
    );
  // console.log('Month_DATA: ', monthTotalOrders)
  // console.info('isMonthLoading_DATA: ', isMonthTotalOrdersLoading)

  const { data: yearTotalOrdersData, isLoading: isYearTotalOrdersDataLoading } =
    useQuery(
      [`dashboard-summary-year`],
      () =>
        getDashboardSummary({
          token: cookies["sofresh-admin-token"],
          role: cookies["sofresh-admin-user"]?.role,
          time: "year",
        }),
      {
        retry: 1,
      }
    );
  // console.log('Year_DATA: ', yearTotalOrdersData)
  // console.log('Year_DATA: ', formatToNigerianNaira(yearTotalOrdersData))
  // console.info('isYearLoading_DATA: ', isYearTotalOrdersDataLoading)
  // console.log('DATA: ', data)

  const columns = [
    {
      name: `Customer's Name`,
      selector: (data: IDashboardData) => data.user.name,
      sortable: true,
    },
    {
      name: `Customer's Email`,
      selector: (data: IDashboardData) => data.user.email,
      sortable: true,
    },
    {
      name: `Pickup/Delivery`,
      selector: (data: IDashboardData) => data.delivery_type,
      sortable: true,
    },
    {
      name: `Delivery Fee`,
      selector: (data: IDashboardData) =>
        data.delivery_fee
          ? formatToNigerianNaira(data?.delivery_fee)
          : formatToNigerianNaira(0),
      sortable: true,
    },
    // {
    //   name: 'Store',
    //   selector: (data: IDashboardData) => data.store.store_name,
    //   sortable: true,
    // },
    {
      name: "Total Price",
      selector: (data: IDashboardData) =>
        formatToNigerianNaira(data.total_amount),
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (data: IDashboardData) =>
        data.order_status ? (
          <span className="badge bg-success">Completed</span>
        ) : (
          <span className="badge bg-warning">Pending</span>
        ),
      sortable: true,
    },
  ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <h1 className="w-100 h3 mb-4">
          <strong>Analytics</strong> Dashboard
        </h1>

        {user?.role === "admin" || user?.role === "subadmin" ? (
          <>
            {isLoading ? (
              <div className="my-8 w-full h-full flex align-items-center justify-center">
                <Oval height="40" width="40" />
              </div>
            ) : (
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">
                <div className="col">
                  <div className="card bg-dark text-light">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Today's Order</h5>
                      {isTodayTotalOrdersLoading ? (
                        <Oval width={40} height={40} />
                      ) : (
                        <p className="card-text h1 text-light">
                          {todayTotalOrders &&
                            formatToNigerianNaira(todayTotalOrders)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card bg-dark text-light">
                    <div className="card-body">
                      <h5 className="card-title pb-4">This Week</h5>
                      {isWeekTotalOrdersLoading ? (
                        <Oval width={40} height={40} />
                      ) : (
                        <p className="card-text h1 text-light">
                          {weekTotalOrders &&
                            formatToNigerianNaira(weekTotalOrders)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card bg-dark text-light">
                    <div className="card-body">
                      <h5 className="card-title pb-4">This Month</h5>
                      {isMonthTotalOrdersLoading ? (
                        <Oval width={40} height={40} />
                      ) : (
                        <p className="card-text h1 text-light">
                          {monthTotalOrders &&
                            formatToNigerianNaira(monthTotalOrders)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card bg-dark text-light">
                    <div className="card-body">
                      <h5 className="card-title pb-4">This Year</h5>
                      {isYearTotalOrdersDataLoading ? (
                        <Oval width={40} height={40} />
                      ) : (
                        <p className="card-text h1 text-light">
                          {yearTotalOrdersData &&
                            formatToNigerianNaira(yearTotalOrdersData)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <h1 className="text-bold text-danger d-flex  align-items-center justify-content-center">
            User is not an Admin or Sub-Admin
          </h1>
        )}

        {(user?.role === "admin" || user?.role === "subadmin") && (
          <div className="row">
            <div className="col-12 d-flex align-center justify-between">
              <h4 className="h4 py-3">Recent Orders</h4>
              <button
                type="button"
                onClick={() => navigate("/orders")}
                className="btn btn-sm h-50 align-self-center"
                style={{ background: "#FD9F28", color: "#FFFFFF" }}>
                View All Orders
              </button>
            </div>

            <div className="col-12 d-flex">
              <div className="card flex-fill">
                {isLoading ? (
                  <div className="my-8 w-full h-full flex align-items-center justify-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      <Table data={data} columns={columns} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default Dashboard;
