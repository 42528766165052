// REACT DEFAULTS
import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCookies } from "react-cookie";
import ReactS3Client from "react-aws-s3-typescript";

// COMPONENTS, UTILS & LIBs
import Modal from "../../../../components/Modal";
import { s3Config } from "../../../../utils/s3Config";
import { toast } from "../../../../components/toast/toast";
import {
  getSingleProductCategory,
  updateProductCategory,
} from "../../../../handlers/productHandler";

// IMAGES & ICONS
import { Oval } from "react-loader-spinner";
import upload from "../../../../assets/uploadPNG.png";

// STYLES
import styles from "./UpdateProductCategoryModal.module.css";

interface ProductCategoryDataType {
  description: string;
  name: string;
  image: string | undefined;
  minimum_price: string | number;
  is_active?: boolean;
}

const EditProductCategoryModal: FC<EditProductCategoryModalProps> = ({
  isOpen,
  onClose,
  slug,
}) => {
  const queryClient = useQueryClient();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const token = cookies["sofresh-admin-token"];
  const navigate = useNavigate();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [imageURL, setImageURL] = useState<string | undefined>("");

  const selectedImgRef = useRef<HTMLImageElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // GET STORE DATA TO PRE-POPULATE THE FORM
  const { data: productCategoryData } = useQuery(
    ["product-category", slug],
    () =>
      getSingleProductCategory({
        token,
        slug: slug,
      })
  );

  // INITIAL PRODUCTS DATA
  const [productCategory, setProductCategory] =
    useState<ProductCategoryDataType>({
      name: "",
      description: "",
      image: imageURL,
      minimum_price: "",
      is_active: true,
    });

  // TO ENSURE THE INITAIL DATA IS NOT UNDEFINED OR EMPTY STRING
  useEffect(() => {
    if (productCategoryData) {
      setProductCategory({
        name: productCategoryData.name ?? "",
        description: productCategoryData.description ?? "",
        minimum_price: productCategoryData.minimum_price ?? "",
        is_active: productCategoryData.is_active ?? true,
        image: productCategoryData.image ?? imageURL,
      });
    }
  }, [imageURL, productCategoryData]);

  // HANDLE PRODUCT IMAGE UPLOAD
  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (file: any) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "products",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (exception) {
      navigate("/product/categories");
      console.error(exception);
    }
  };

  const handleProductImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setUploadLoading(true);

    try {
      const imageUrl = await handleFileUpload(file);
      setImageURL(imageUrl);
      setProductCategory((prev) => ({
        ...prev,
        image: imageUrl,
      }));
    } catch (error) {
      console.error(error);
      // navigate('/product/categories')
      toast.error("Failed to upload image");
    } finally {
      setUploadLoading(false);
    }
  }; // END OF HANDLE PRODUCT IMAGE UPLOAD

  // DELETE SELECTED IMAGE
  const handleDeleteImage = () => {
    if (window.confirm("Do you want to delete selected image?")) {
      selectedImgRef?.current?.setAttribute("src", upload);
    } else {
      return;
    }
  };

  // FORM CONTROL FOR TEXT INPUTS
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!isOpen) return null;
    const { name, value } = event.target;
    setProductCategory((prev) => ({ ...prev, [name]: value }));
  };

  // FORM CONTROL FOR SELECT OPTIONS INPUTS(is_active: string <true|false>)
  const handleSetActive = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "true") {
      setProductCategory((prev) => ({ ...prev, is_active: true }));
    } else {
      setProductCategory((prev) => ({ ...prev, is_active: false }));
    }
  };

  // // MUTATE FORM DATA BEFORE SUBMISSION
  // const {
  //   mutate: updateProductCategoryMutation,
  //   isLoading: isUpdateProductCategoryMutationLoading,
  // } = useMutation(updateProductCategory, {
  //   onSuccess: async res => {
  //     await queryClient.invalidateQueries(['product-categories'])
  //   },
  //   onError: (e: string) => {
  //     console.error({ e })
  //     navigate('/product/categories')
  //     toast.error(e)
  //   },
  // })

  // // HANDLE FORM SUBMISSION
  // const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault()

  //   try {
  //     updateProductCategoryMutation({
  //       token,
  //       data: productCategory,
  //       slug,
  //     })
  //     await queryClient.invalidateQueries(['product-categories'])
  //     onClose()
  //     toast.success('Product Category updated successfully')
  //   } catch (error) {
  //     console.error(error)
  //     navigate('/product/categories')
  //     toast.error('Failed to update product category')
  //   }
  // }

  // const {
  //   mutate: updateProductCategoryMutation,
  //   isLoading: isUpdateProductCategoryMutationLoading,
  // } = useMutation(updateProductCategory, {
  //   onSuccess: async res => {
  //     await queryClient.invalidateQueries(['product-categories'])
  //     toast.success('Subadmin updated successfully')
  //   },
  //   onError: (e: string) => {
  //     console.log({ e })
  //     // navigate('/sub-admins')
  //     toast.error(e)
  //   },
  // })

  // const handleSubmit = (values: any) => {
  //   console.log('productCategory: ', productCategory)

  //   updateProductCategoryMutation({
  //     token,
  //     data: productCategory,
  //     slug,
  //   })
  // }

  // MUTATE FORM DATA BEFORE SUBMISSION
  const {
    mutate: updateProductCategoryMutation,
    isLoading: isUpdateProductCategoryMutationLoading,
  } = useMutation(updateProductCategory, {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries(["product-categories"]);
      toast.success("Product category updated successfully");
    },
    onError: (e: string) => {
      console.error({ e });
      toast.error("Failed to update product");
    },
  });

  // HANDLE FORM SUBMISSION
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      updateProductCategoryMutation({
        token: cookies["sofresh-admin-token"],
        data: productCategory,
        slug,
      });
      await queryClient.invalidateQueries(["product-categories"]);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      key={`Update Product Category`}
      title={`Update Product Category`}
      subTitle={`Upload image and enter information below to add extras`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <div className={styles.modalBody}>
          <div className={`form-group pt-4 pb-2 ${styles.modalImageContainer}`}>
            <div>
              {uploadLoading || isUpdateProductCategoryMutationLoading ? (
                <div className="my-5 d-flex flex-column  align-items-center justify-content-center">
                  <Oval height="40" width="40" />
                </div>
              ) : (
                <img
                  ref={selectedImgRef}
                  src={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : productCategoryData?.image
                      ? productCategoryData?.image
                      : upload
                  }
                  alt="preview"
                  onClick={handleUploadBtnClick}
                  className={
                    productCategoryData?.image || selectedFile
                      ? styles.imageContainer
                      : styles.uploadIcon
                  }
                />
              )}
              <input
                type="file"
                id="uploadPlanImage"
                accept="image/*"
                ref={fileInputRef}
                className="d-none"
                onChange={handleProductImageUpload}
              />
            </div>

            <div className={styles.imageDisplayControls}>
              <div className="w-100 pt-2">
                <p className={`${styles.modalImageText}`}>
                  <label htmlFor="uploadPlanImage">
                    <span className={styles.modalImageSpan}>
                      Click to upload{" "}
                    </span>
                  </label>
                  <span> a PNG, JPEG or WEBP image</span>
                  <span className={styles.modalImageText}>
                    {" "}
                    (image size should not exceed 400x400)
                  </span>
                </p>
              </div>
              <div
                className={`${styles.imageContolBtns} d-flex  align-items-center justify-content-between gap-3`}
                style={{ width: "85%" }}
              >
                <button
                  type="button"
                  onClick={handleDeleteImage}
                  className={`btn btn-sm ${styles.deleteImageBtn}`}
                  disabled={selectedImgRef?.current?.src === upload}
                >
                  Delete Image
                </button>
                <label
                  htmlFor="uploadPlanImage"
                  className="btn btn-sm chooseNewImageBtn"
                >
                  Pick New Image
                </label>
              </div>
            </div>
          </div>

          <div className="form-group py-2">
            <label className={styles.formLabel} htmlFor="name">
              Name
            </label>
            <input
              type="text"
              className={`form-control ${styles.formField}`}
              id="name"
              name="name"
              value={productCategory.name}
              onChange={handleInputChange}
              placeholder="Enter name of product Category"
              required
            />
          </div>

          <div className="form-group py-2">
            <label className={styles.formLabel} htmlFor="status">
              Status:
            </label>
            <select
              className={`form-control ${styles.formField}`}
              id="status"
              name="status"
              value={productCategory.is_active ? "true" : "false"}
              onChange={handleSetActive}
            >
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>

          <div className="form-group py-2">
            <label className={styles.formLabel} htmlFor="description">
              Description
            </label>
            <textarea
              className={`form-control ${styles.formField}`}
              id="description"
              name="description"
              value={productCategory.description}
              onChange={handleInputChange}
              placeholder="Enter description"
              required
            />
          </div>

          <div className="form-group py-2">
            <label className={styles.formLabel} htmlFor="minimum_price">
              Minimum Price
            </label>
            <input
              type="text"
              className={`form-control ${styles.formField}`}
              id="minimum_price"
              name="minimum_price"
              value={productCategory.minimum_price}
              onChange={handleInputChange}
              placeholder="Enter Minimum Price"
              inputMode="numeric"
              pattern="[0-9]+(\.[0-9]{1,2})?"
              required
            />
          </div>
        </div>

        <div className={`modal-footer pt-4 ${styles.btnContainer}`}>
          <button
            type="submit"
            className={`btn form-control ${styles.btnColor}`}
          >
            {isUpdateProductCategoryMutationLoading ? (
              <Oval color="white" wrapperClass="ml-2" height={10} width={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default EditProductCategoryModal;
